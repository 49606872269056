import React from 'react';
import cn from 'classnames';
import { UploadImage } from '../../UploadMultipleImages';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
// Components
import { Button } from 'antd';

import styles from '../../UploadMultipleImages.module.scss';

type ImageProps = {
  index: number;
  image: UploadImage;
  setIsMainImage: (index: number) => void;
  isMainImage: number | null;
  onImageUpdate: (index: number) => void;
  onImageRemove: (index: number) => void;
};

const ImageItem = ({
  index,
  image,
  isMainImage,
  setIsMainImage,
  onImageUpdate,
  onImageRemove,
}: ImageProps): JSX.Element => {
  return (
    <div className={styles.imageItem}>
      <img
        src={image['data_url']}
        alt=""
        className={cn(styles.singleImage, {
          [styles.isMain]: isMainImage === index,
        })}
      />

      <div className={styles.imageButtons}>
        <Button
          className={styles.imageButton}
          onClick={() => onImageUpdate(index)}
        >
          <EditOutlined />
        </Button>

        <Button
          className={styles.imageButton}
          onClick={() => onImageRemove(index)}
        >
          <DeleteOutlined />
        </Button>
      </div>

      <Button
        className={cn(styles.imageMainButton, {
          [styles.isMain]: isMainImage === index,
        })}
        onClick={() => {
          setIsMainImage(index);
        }}
      >
        Set as main
      </Button>
    </div>
  );
};

export default ImageItem;
