import React from 'react';
import { UploadImage } from '../UploadMultipleImages';
import ImageItem from './ImageItem/ImageItem';

import styles from '../UploadMultipleImages.module.scss';

type MultipleImagesProps = {
  imageList: UploadImage[];
  onImageUpdate: (index: number) => void;
  onImageRemove: (index: number) => void;
  setIsMainImage: (index: number) => void;
  isMainImage: number | null;
};

const MultipleImagesList = ({
  imageList,
  onImageUpdate,
  onImageRemove,
  setIsMainImage,
  isMainImage,
}: MultipleImagesProps): JSX.Element => {
  return (
    <div className={styles.imagesList}>
      {imageList.map((image, index) => (
        <ImageItem
          image={image}
          index={index}
          key={'custom_image_' + index}
          isMainImage={isMainImage}
          setIsMainImage={setIsMainImage}
          onImageUpdate={onImageUpdate}
          onImageRemove={onImageRemove}
        />
      ))}
    </div>
  );
};

export default MultipleImagesList;
