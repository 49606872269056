import { gql } from '@apollo/client';

export const InterviewFields = gql`
  fragment InterviewFields on Interview {
    id
    adminNotes
    notes
    completedAt
    createdAt
    creationType
    interviewee {
      id
      firstName
      lastName
      slug
      storeDetails {
        storeName
      }
      sports {
        id
        name
      }
    }
    interviewer {
      id
      firstName
      lastName
      slug
      interviewerDetails {
        id
      }
    }
    isError
    notes
    scheduledMeetingLink
    source {
      id
      type
    }
    status
    stream {
      id
      slug
      mainImageUrl
      scheduleDate
      name
      timeZone {
        tzCode
        offset
      }
    }
    streamCohostToken
    updatedAt
  }
`;
