import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Modal } from 'antd';
// Helpers
import { getPublicStreamDate } from 'helpers/utils';
// Api
import { GET_MY_INTERVIEWS } from 'api/interview/queries';
// Types
import {
  GetMyInterviews,
  GetMyInterviewsVariables,
  GetMyInterviews_getMyInterviews_entities,
} from 'api/interview/types/GetMyInterviews';
import {
  SortDirection,
  InterviewerTasksOrderBy,
} from 'api/graphql-global-types';
// UI
import Table from 'ui/Table';
import { SortedInfo, TableFilter } from 'ui/Table';
// Components
import InterviewDetailsModal from '../components/InterviewDetailsModal/InterviewDetailsModal';
import InterviewsActions from '../components/InterviewsActions/InterviewsActions';
import ScheduleInterviewModal from '../components/ScheduleInterviewModal/ScheduleInterviewModal';
import UpdateInfoModal from '../components/UpdateInfoModal/UpdateInfoModal';
// Columns
import { createdAtColumn, statusColumn } from 'pages/Interviews/common/Columns';
// Styles
import styles from './MyInterviewsTable.module.scss';

const MyInterviewsTable = (): JSX.Element => {
  const [isDetailsModalVisible, setIsDetailsModalVisible] =
    useState<boolean>(false);

  const [isReschedule, setIsReschedule] = useState<boolean>(false);

  const [selectedRecord, setSelectedRecord] =
    useState<GetMyInterviews_getMyInterviews_entities | null>(null);

  const [isScheduleModalVisible, setIsScheduleModalVisible] =
    useState<boolean>(false);

  const [isUpdateInfoModalVisible, setIsUpdateInfoModalVisible] =
    useState<boolean>(false);

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<InterviewerTasksOrderBy>>({
    order: SortDirection.DESC,
    key: InterviewerTasksOrderBy.createdAt,
  });

  const showDetailsModal = (
    record: GetMyInterviews_getMyInterviews_entities | null
  ) => {
    setSelectedRecord(record);
    setIsDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setIsDetailsModalVisible(false);
    setSelectedRecord(null);
  };

  const showScheduleModal = (
    record: GetMyInterviews_getMyInterviews_entities | null,
    isReschedule?: boolean
  ) => {
    setIsScheduleModalVisible(true);
    setSelectedRecord(record);
    setIsReschedule(isReschedule || false);
  };

  const hideScheduleModal = () => {
    setIsScheduleModalVisible(false);
    setSelectedRecord(null);
    setIsReschedule(false);
  };

  const showUpdateInfoModal = (
    record: GetMyInterviews_getMyInterviews_entities | null
  ) => {
    setIsUpdateInfoModalVisible(true);
    setSelectedRecord(record);
  };

  const hideUpdateInfoModal = () => {
    setIsUpdateInfoModalVisible(false);
    setSelectedRecord(null);
  };

  const getInterviewsInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;

      if (key === 'interviewType') {
        return (input['sources'] = value);
      }
      if (key === 'scheduleDate') {
        return (input['orderBy'] = 'scheduleAt');
      }
    });

    return input;
  };

  const { data, loading } = useQuery<GetMyInterviews, GetMyInterviewsVariables>(
    GET_MY_INTERVIEWS,
    {
      variables: {
        input: { ...getInterviewsInput() },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const interviewTypeColumn = {
    title: 'Interview type',
    dataIndex: ['source', 'type'],
    key: 'type',
    align: 'left' as const,
    width: 120,
  };

  const interviewIdColumn = {
    title: 'Interview ID',
    dataIndex: 'interviewId',
    key: 'interviewId',
    align: 'center' as const,
    width: 130,
    render: function InterviewIdWrap(
      id: string,
      record: GetMyInterviews_getMyInterviews_entities
    ) {
      return (
        <span
          onKeyDown={() => showDetailsModal(record)}
          onClick={() => showDetailsModal(record)}
          className={styles.detailsModalLink}
          role="button"
          tabIndex={0}
        >
          {record.id}
        </span>
      );
    },
  };

  const storeNameColumn = {
    title: 'Store name',
    dataIndex: 'interviewee',
    key: 'interviewee',
    align: 'left' as const,
    width: 120,
    render: function IntervieweeWrap(
      id: string,
      record: GetMyInterviews_getMyInterviews_entities
    ) {
      return <span>{record.interviewee?.storeDetails?.storeName || ''} </span>;
    },
  };

  const actionColumn = {
    title: 'Action',
    dataIndex: 'id',
    fixed: 'right' as const,
    align: 'center' as const,
    width: 90,
    render: function ActionsWrap(
      id: string,
      record: GetMyInterviews_getMyInterviews_entities
    ) {
      return (
        <InterviewsActions
          interview={record}
          showScheduleModal={showScheduleModal}
          showUpdateInfoModal={showUpdateInfoModal}
        />
      );
    },
  };

  const scheduleDateColumn = {
    title: 'Schedule date',
    dataIndex: 'scheduleDate',
    key: 'scheduledAt',
    align: 'center' as const,
    width: 110,
    sorterType: 'date',
    render: (
      id: string,
      record: GetMyInterviews_getMyInterviews_entities
    ): JSX.Element => {
      return (
        <>
          {record?.stream?.scheduleDate
            ? getPublicStreamDate(
                record?.stream?.scheduleDate,
                record?.stream?.timeZone
              )
            : '-'}
        </>
      );
    },
  };

  const interviewerColumns = [
    interviewIdColumn,
    storeNameColumn,
    interviewTypeColumn,
    statusColumn,
    createdAtColumn,
    scheduleDateColumn,
    actionColumn,
  ];

  return (
    <>
      <Table<GetMyInterviews_getMyInterviews_entities, InterviewerTasksOrderBy>
        columns={interviewerColumns}
        data={data?.getMyInterviews?.entities || []}
        scroll={{ x: 1280 }}
        loading={loading}
        total={data?.getMyInterviews?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      <Modal
        title="Interview details"
        visible={!!selectedRecord && isDetailsModalVisible}
        onCancel={hideDetailsModal}
        destroyOnClose
        footer={null}
      >
        <InterviewDetailsModal
          interviewId={selectedRecord?.id || ''}
          hideDetailsModal={hideDetailsModal}
        />
      </Modal>

      <Modal
        title="Update Interview info"
        visible={!!selectedRecord && isUpdateInfoModalVisible}
        onCancel={hideUpdateInfoModal}
        destroyOnClose
        footer={null}
      >
        <UpdateInfoModal
          interviewId={selectedRecord?.id || ''}
          hideUpdateInfoModal={hideUpdateInfoModal}
        />
      </Modal>

      <Modal
        title="Schedule interview"
        visible={!!selectedRecord && isScheduleModalVisible}
        onCancel={hideScheduleModal}
        destroyOnClose
        footer={null}
      >
        <ScheduleInterviewModal
          isReschedule={isReschedule}
          interview={selectedRecord || null}
          hideScheduleModal={hideScheduleModal}
        />
      </Modal>
    </>
  );
};

export default MyInterviewsTable;
