import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import {
  from,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { ApolloLink } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { notification } from 'antd';
import { WifiOutlined } from '@ant-design/icons';
// Types
import { ArticleTypes, UserRole } from 'api/graphql-global-types';
// Constants
import {
  LOGIN,
  HOME_PAGE_MANAGEMENT,
  DASHBOARD,
  TAGS,
  // MANAGE_MERCH_GEN,
  // MANAGE_MERCH,
  MANAGE_MERCH_TSHIRT,
  MANAGE_MERCH_HOODIE,
  MANAGE_MERCH_HAT,
  MANAGE_MERCH_JOGGERS,
  MANAGE_MERCH_RASH_GUARD,
  MANAGE_ATHLETES_STORES,
  MANAGE_ATHLETES_STORES_WITH_PAGINATION,
  ATHLETE_DASHBOARD_GEN,
  ATHLETE_DASHBOARD,
  MANAGE_ATHLETE_AMA_REQUESTS_GEN,
  MANAGE_ATHLETE_AMA_REQUESTS,
  MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN,
  MANAGE_ATHLETE_SCHEDULED_STREAMS,
  SETUP_ATHLETE_STREAM_GEN,
  SETUP_ATHLETE_STREAM,
  SETUP_ATHLETE_AMA_GEN,
  SETUP_ATHLETE_AMA,
  MANAGE_ATHLETE_MERCH_GEN,
  MANAGE_ATHLETE_MERCH,
  MANAGE_ATHLETE_PRODUCTS,
  MANAGE_ATHLETE_PRODUCTS_GEN,
  MANAGE_ATHLETE_EXPERIENCE,
  MANAGE_ATHLETE_EXPERIENCE_GEN,
  MANAGE_ATHLETE_DESIGNS_GEN,
  MANAGE_ATHLETE_DESIGNS,
  MANAGE_ATHLETE_BIO_GEN,
  MANAGE_ATHLETE_BIO,
  ATHLETE_SCHEDULED_EVENTS_GEN,
  ATHLETE_SCHEDULED_EVENTS,
  ORGANIZATION_SCHEDULED_EVENTS_GEN,
  ORGANIZATION_SCHEDULED_EVENTS,
  CONTENT_CREATOR_SCHEDULED_EVENTS_GEN,
  CONTENT_CREATOR_SCHEDULED_EVENTS,
  MEDIA_FEED_ATHLETE_GEN,
  MEDIA_FEED_ATHLETE,
  ATHLETE_STORE_SALES_GEN,
  ATHLETE_STORE_SALES,
  CUSTOMERS,
  CUSTOMERS_WITH_PAGINATION,
  CUSTOMER_PURCHASES_GEN,
  CUSTOMER_PURCHASES,
  CUSTOMER_SETTINGS_GEN,
  CUSTOMER_SETTINGS,
  CUSTOMER_ADDRESSES_GEN,
  CUSTOMER_ADDRESSES,
  CUSTOMER_PAYMENT_GEN,
  CUSTOMER_PAYMENT,
  ALL_PRODUCT_MEMORABILIA,
  ALL_AMA_REQUESTS,
  STREAMS_CALENDAR,
  ALL_STREAMS_TABLE,
  WATCH_STREAM,
  PROMO_CODES,
  BULK_UPLOAD,
  DESIGN_REQUESTS,
  DESIGNERS,
  EDITING_REQUESTS,
  MY_EDITING_REQUESTS,
  VIDEO_EDITOR_PROFILE,
  VIDEO_EDITORS,
  MY_DESIGN_REQUESTS,
  REDIRECTS,
  WATCH_STREAM_GEN,
  MERCH_SALES_REPORT,
  AMA_SALES_REPORT,
  STREAM_SALES_REPORT,
  MANAGE_WATCH_STREAM_MODERATORS_GEN,
  LIST_OF_ASSIGNED_STREAMS,
  GENERAL_SALES,
  EXPERIENCE_SALES,
  ADMINS,
  ACCOUNT,
  DESIGN_REQUEST_DETAILS_GEN,
  DESIGN_REQUEST_RESULT_DETAILS_GEN,
  DESIGN_REQUEST_DETAILS,
  DESIGN_REQUEST_RESULT_DETAILS,
  MANAGE_MESSAGES,
  MANAGE_MESSAGES_WITH_PAGINATION,
  CAMPAIGNS,
  MANAGE_ORGANIZATIONS_STORES,
  MANAGE_CONTENT_CREATORS_STORES,
  MANAGE_CONTENT_CREATOR_PRODUCTS_GEN,
  MANAGE_CONTENT_CREATOR_PRODUCTS,
  MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN,
  MANAGE_CONTENT_CREATOR_EXPERIENCE,
  MANAGE_ORGANIZATION_EXPERIENCE,
  MANAGE_ORGANIZATION_EXPERIENCE_GEN,
  MANAGE_ORGANIZATION_PRODUCTS,
  MANAGE_ORGANIZATION_PRODUCTS_GEN,
  MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION,
  ORGANIZATION_DASHBOARD_GEN,
  ORGANIZATION_DASHBOARD,
  MANAGE_ORGANIZATION_AMA_REQUESTS_GEN,
  MANAGE_ORGANIZATION_AMA_REQUESTS,
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN,
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS,
  SETUP_ORGANIZATION_STREAM_GEN,
  SETUP_ORGANIZATION_STREAM,
  SETUP_ORGANIZATION_AMA_GEN,
  SETUP_ORGANIZATION_AMA,
  MANAGE_ORGANIZATION_MERCH_GEN,
  MANAGE_ORGANIZATION_MERCH,
  MANAGE_ORGANIZATION_DESIGNS_GEN,
  MANAGE_ORGANIZATION_DESIGNS,
  MANAGE_ORGANIZATION_BIO_GEN,
  MEDIA_FEED_ORGANIZATION_GEN,
  MEDIA_FEED_ORGANIZATION,
  MANAGE_ORGANIZATION_BIO,
  ORGANIZATION_STORE_SALES_GEN,
  ORGANIZATION_STORE_SALES,
  MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION,
  CONTENT_CREATOR_DASHBOARD_GEN,
  CONTENT_CREATOR_DASHBOARD,
  MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN,
  MANAGE_CONTENT_CREATOR_AMA_REQUESTS,
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN,
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS,
  SETUP_CONTENT_CREATOR_STREAM_GEN,
  SETUP_CONTENT_CREATOR_STREAM,
  SETUP_CONTENT_CREATOR_AMA_GEN,
  SETUP_CONTENT_CREATOR_AMA,
  MANAGE_CONTENT_CREATOR_MERCH_GEN,
  MANAGE_CONTENT_CREATOR_DESIGNS_GEN,
  MANAGE_CONTENT_CREATOR_DESIGNS,
  MANAGE_CONTENT_CREATOR_BIO_GEN,
  MEDIA_FEED_CONTENT_CREATOR_GEN,
  MEDIA_FEED_CONTENT_CREATOR,
  MANAGE_CONTENT_CREATOR_BIO,
  CONTENT_CREATOR_STORE_SALES_GEN,
  CONTENT_CREATOR_STORE_SALES,
  MANAGE_CONTENT_CREATOR_MERCH,
  MANUAL_MERCH_MERGE,
  INTERVIEWS,
  INTERVIEWERS,
  INTERVIEW_SETTINGS,
  MY_INTERVIEWS,
  MY_SCHEDULED_EVENTS,
  MY_AVAILABILITY,
  VIDEO_EDIT_REQUEST_SETTINGS,
  ARTICLES,
  ARTICLES_WITH_PAGINATION,
} from 'constants/routes';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_ITEMS_PER_PAGE } from 'ui/Table';
// Config
import ENV from 'api/env';
// Hooks
import { useAppContext } from 'hooks';
// Helpers
import { handleAuthError } from 'helpers/auth';
import { getAuthUserFromCookies } from 'helpers/cookies';
// Watchers
import CookieTokenWatcher from 'watchers/CookieTokenWatcher';
// Ui
import { LayoutWrapper } from 'ui/LayoutWrapper/LayoutWrapper';
// Components
import Login from 'pages/Login/Login';
import NotFound from 'pages/NotFound';
import HomePageManagement from 'pages/HomePageManagement';
import Dashboard from 'pages/Dashboard';
import ManageTags from 'pages/ManageTags';
import ListOfAssignedStreams from 'pages/ListOfAssignedStreams';
// import ManageMerch from 'components/common/ManageMerch';
import MerchConstructor from 'components/common/ManageMerch/CreateEditMerch/MerchConstructor/MerchConstructor';
import ManageAthletesStores, {
  ManageAthleteStoreStatus,
} from 'pages/ManageStores/athletes/ManageStores/ManageStores';
import ManageAthleteAmaRequests from 'pages/ManageStores/athletes/ManageAthleteAmaRequests';
import ManageAthleteScheduledStreams from 'pages/ManageStores/athletes/ManageAthleteScheduledStreams';
import SetupAthleteStream from 'pages/ManageStores/athletes/SetupAthleteStream';
import SetupAthleteAma from 'pages/ManageStores/athletes/SetupAthleteAma';
import ManageAthleteMerch from 'pages/ManageStores/athletes/ManageAthleteMerch';
import DesignRequestDetails from 'pages/DesignRequestDetails/DesignRequestDetails';
import DesignResultDetails from 'pages/DesignResultDetails/DesignResultDetails';
import ManageAthleteDesigns from 'pages/ManageStores/athletes/ManageAthleteDesigns';
import ManageAthleteBio from 'pages/ManageStores/athletes/ManageAthleteBio';
import ManageMediaFeed from 'components/common/MediaFeed/MediaFeed';
import AthleteStoreSales from 'pages/ManageStores/athletes/AthleteStoreSales';
import ManageOrganizationsStores, {
  ManageOrganizationStoreStatus,
} from 'pages/ManageStores/organizations/ManageStores/ManageStores';
import ManageOrganizationAmaRequests from 'pages/ManageStores/organizations/ManageOrganizationAmaRequests';
import ManageOrganizationScheduledStreams from 'pages/ManageStores/organizations/ManageOrganizationScheduledStreams';
import SetupOrganizationStream from 'pages/ManageStores/organizations/SetupOrganizationStream';
import SetupOrganizationAma from 'pages/ManageStores/organizations/SetupOrganizationAma';
import ManageOrganizationMerch from 'pages/ManageStores/organizations/ManageOrganizationMerch';
import ManageOrganizationDesigns from 'pages/ManageStores/organizations/ManageOrganizationDesigns';
import ManageOrganizationBio from 'pages/ManageStores/organizations/ManageOrganizationBio';
import OrganizationStoreSales from 'pages/ManageStores/organizations/OrganizationStoreSales';
import ManageOrganizationProducts from 'pages/ManageStores/organizations/ManageOrganizationProducts';
import ManageOrganizationExperience from 'pages/ManageStores/organizations/ManageOrganizationExperience';
import ManageContentCreatorsStores, {
  ManageContentCreatorStoreStatus,
} from 'pages/ManageStores/contentCreators/ManageStores/ManageStores';
import ManageContentCreatorAmaRequests from 'pages/ManageStores/contentCreators/ManageContentCreatorAmaRequests';
import ManageContentCreatorScheduledStreams from 'pages/ManageStores/contentCreators/ManageContentCreatorScheduledStreams';
import SetupContentCreatorStream from 'pages/ManageStores/contentCreators/SetupContentCreatorStream';
import SetupContentCreatorAma from 'pages/ManageStores/contentCreators/SetupContentCreatorAma';
import ManageContentCreatorMerch from 'pages/ManageStores/contentCreators/ManageContentCreatorMerch';
import ManageContentCreatorDesigns from 'pages/ManageStores/contentCreators/ManageContentCreatorDesigns';
import ManageContentCreatorBio from 'pages/ManageStores/contentCreators/ManageContentCreatorBio';
import ContentCreatorStoreSales from 'pages/ManageStores/contentCreators/ContentCreatorStoreSales';
import ManageContentCreatorProducts from 'pages/ManageStores/contentCreators/ManageContentCreatorProducts';
import ManageContentCreatorExperience from 'pages/ManageStores/contentCreators/ManageContentCreatorExperience';
import Customers from 'pages/Customers';
import CustomerPurchases from 'pages/ManageAccount/components/CustomerPurchases';
import CustomerSettings from 'pages/ManageAccount/components/CustomerSettings';
import CustomerAddresses from 'pages/ManageAccount/components/CustomerAddresses';
import CustomerPayment from 'pages/ManageAccount/components/CustomerPayment';
import AllAmaRequests from 'pages/AllAmaRequests';
import StreamsCalendar from 'pages/StreamsCalendar/StreamsCalendar';
import AllStreams from 'pages/AllStreams';
import Moderators from 'pages/Moderators/Moderators';
import Admins from 'pages/Admins/Admins';
import Account from 'pages/Account/Account';
import WatchStream from 'pages/WatchStream';
import PromoCodes from 'pages/PromoCodes';
import DesignRequests from 'pages/DesignRequests';
import DesignersPage from 'pages/Designers';
import MyDesignRequests from 'pages/MyDesignRequests';
import MyInterviews from 'pages/Interviews/Interviewer';
import AthleteSalesReport from 'pages/AthleteSalesReport';
import Redirects from 'pages/Redirects';
import GeneralSales from 'pages/GeneralSales/GeneralSales';
import ExperienceSales from 'pages/ExperienceSales/ExperienceSales';
import ManageAthleteProducts from 'pages/ManageStores/athletes/ManageAthleteProducts';
import ManageAthleteExperience from 'pages/ManageStores/athletes/ManageAthleteExperience';
import BulkUpload from 'pages/BulkUpload/BulkUpload';
import ManualMerchMerge from 'pages/ManualMerchMerge/ManualMerchMerge';
import AmaSalesReport from 'pages/AmaSalesReport';
import StreamSalesReport from 'pages/StreamSalesReport';
import AllProductMemorabilia from 'pages/AllProductMemorabilia/AllProductMemorabilia';
import ManageMessages, { ManageMessagesTabs } from 'pages/ManageMessages';
import Campaigns from 'pages/Campaigns';
import Interviewers from 'pages/Interviewers/Interviewers';
import InterviewSettings from 'pages/InterviewSettings/InterviewSettings';
import Interviews from 'pages/Interviews/Admin';
import VideoEditorsPage from 'pages/VideoEditors';
import VideoEditingRequestPage from 'pages/VideoEditingRequest/Admin';
import VideoEditorProfile from 'pages/VideoEditingRequest/VideoEditorView/VideoEditorProfile/VideoEditorProfile';
import MyVideoEditRequests from 'pages/VideoEditingRequest/VideoEditorView';
import ScheduledEvents from 'pages/Interviews/Admin/ScheduledEvents/ScheduledEvents';
import MyScheduledEvents from 'pages/Interviews/Interviewer/components/MyScheduledEvents/MyScheduledEvents';
import MyAvailability from 'pages/Interviews/Interviewer/components/MyAvailability/MyAvailability';
import VideoEditRequestSettings from 'pages/VideoEditRequestSettings/VideoEditRequestSettings';
import Articles from 'pages/Articles';

export const CUSTOMERS_DEFAULT_ROUTE = `${CUSTOMERS}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`;
export const MANAGE_ATHLETES_STORES_DEFAULT_ROUTE = `${MANAGE_ATHLETES_STORES}/${ManageAthleteStoreStatus?.Current}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`;
export const MANAGE_ORGANIZATIONS_STORES_DEFAULT_ROUTE = `${MANAGE_ORGANIZATIONS_STORES}/${ManageOrganizationStoreStatus?.Current}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`;
export const MANAGE_CONTENT_CREATORS_STORES_DEFAULT_ROUTE = `${MANAGE_CONTENT_CREATORS_STORES}/${ManageContentCreatorStoreStatus?.Current}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`;
// export const MANAGE_MESSAGES_DEFAULT_ROUTE = `${MANAGE_MESSAGES}/${ManageMessagesTabs?.Contacts}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`; TODO: uncomment once US number is approved
export const MANAGE_MESSAGES_DEFAULT_ROUTE = `${MANAGE_MESSAGES}/${ManageMessagesTabs?.Notifications}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`;
export const ARTICLES_DEFAULT_ROUTE = `${ARTICLES}/${ArticleTypes?.Sponsored}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`;

const ADMIN_ROUTE_ITEMS: Record<string, string> = {
  HOME_PAGE_MANAGEMENT: HOME_PAGE_MANAGEMENT,
  DASHBOARD: DASHBOARD,
  TAGS: TAGS,

  // MANAGE_MERCH_GEN: MANAGE_MERCH_GEN,
  // MANAGE_MERCH: MANAGE_MERCH,
  MANAGE_MERCH_TSHIRT: MANAGE_MERCH_TSHIRT,
  MANAGE_MERCH_HOODIE: MANAGE_MERCH_HOODIE,
  MANAGE_MERCH_HAT: MANAGE_MERCH_HAT,
  MANAGE_MERCH_JOGGERS: MANAGE_MERCH_JOGGERS,
  MANAGE_MERCH_RASH_GUARD: MANAGE_MERCH_RASH_GUARD,

  MANAGE_ATHLETES_STORES: MANAGE_ATHLETES_STORES_WITH_PAGINATION,
  ATHLETE_DASHBOARD_GEN: ATHLETE_DASHBOARD_GEN,
  ATHLETE_DASHBOARD: ATHLETE_DASHBOARD,
  MANAGE_ATHLETE_AMA_REQUESTS_GEN: MANAGE_ATHLETE_AMA_REQUESTS_GEN,
  MANAGE_ATHLETE_AMA_REQUESTS: MANAGE_ATHLETE_AMA_REQUESTS,
  MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN: MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN,
  MANAGE_ATHLETE_SCHEDULED_STREAMS: MANAGE_ATHLETE_SCHEDULED_STREAMS,
  SETUP_STREAM: SETUP_ATHLETE_STREAM_GEN,
  SETUP_ATHLETE_STREAM: SETUP_ATHLETE_STREAM,
  SETUP_AMA: SETUP_ATHLETE_AMA_GEN,
  SETUP_ATHLETE_AMA: SETUP_ATHLETE_AMA,
  MANAGE_ATHLETE_MERCH_GEN: MANAGE_ATHLETE_MERCH_GEN,
  MANAGE_ATHLETE_MERCH: MANAGE_ATHLETE_MERCH,
  MANAGE_ATHLETE_PRODUCTS_GEN: MANAGE_ATHLETE_PRODUCTS_GEN,
  MANAGE_ATHLETE_PRODUCTS: MANAGE_ATHLETE_PRODUCTS,
  MANAGE_ATHLETE_EXPERIENCE_GEN: MANAGE_ATHLETE_EXPERIENCE_GEN,
  MANAGE_ATHLETE_EXPERIENCE: MANAGE_ATHLETE_EXPERIENCE,

  MANAGE_ORGANIZATION_PRODUCTS_GEN: MANAGE_ORGANIZATION_PRODUCTS_GEN,
  MANAGE_ORGANIZATION_PRODUCTS: MANAGE_ORGANIZATION_PRODUCTS,
  MANAGE_ORGANIZATION_EXPERIENCE_GEN: MANAGE_ORGANIZATION_EXPERIENCE_GEN,
  MANAGE_ORGANIZATION_EXPERIENCE: MANAGE_ORGANIZATION_EXPERIENCE,

  MANAGE_CONTENT_CREATOR_PRODUCTS_GEN: MANAGE_CONTENT_CREATOR_PRODUCTS_GEN,
  MANAGE_CONTENT_CREATOR_PRODUCTS: MANAGE_CONTENT_CREATOR_PRODUCTS,
  MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN: MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN,
  MANAGE_CONTENT_CREATOR_EXPERIENCE: MANAGE_CONTENT_CREATOR_EXPERIENCE,

  MANAGE_ATHLETE_DESIGNS_GEN: MANAGE_ATHLETE_DESIGNS_GEN,
  MANAGE_ATHLETE_DESIGNS: MANAGE_ATHLETE_DESIGNS,
  MANAGE_ATHLETE_BIO_GEN: MANAGE_ATHLETE_BIO_GEN,
  MANAGE_ATHLETE_BIO: MANAGE_ATHLETE_BIO,
  MEDIA_FEED_ATHLETE_GEN: MEDIA_FEED_ATHLETE_GEN,
  MEDIA_FEED_ATHLETE: MEDIA_FEED_ATHLETE,
  ATHLETE_STORE_SALES_GEN: ATHLETE_STORE_SALES_GEN,
  ATHLETE_STORE_SALES: ATHLETE_STORE_SALES,

  ATHLETE_SCHEDULED_EVENTS_GEN: ATHLETE_SCHEDULED_EVENTS_GEN,
  ATHLETE_SCHEDULED_EVENTS: ATHLETE_SCHEDULED_EVENTS,

  ORGANIZATION_SCHEDULED_EVENTS_GEN: ORGANIZATION_SCHEDULED_EVENTS_GEN,
  ORGANIZATION_SCHEDULED_EVENTS: ORGANIZATION_SCHEDULED_EVENTS,

  CONTENT_CREATOR_SCHEDULED_EVENTS_GEN: CONTENT_CREATOR_SCHEDULED_EVENTS_GEN,
  CONTENT_CREATOR_SCHEDULED_EVENTS: CONTENT_CREATOR_SCHEDULED_EVENTS,

  MANAGE_ORGANIZATION_STORES: MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION,
  ORGANIZATION_DASHBOARD_GEN: ORGANIZATION_DASHBOARD_GEN,
  ORGANIZATION_DASHBOARD: ORGANIZATION_DASHBOARD,
  MANAGE_ORGANIZATION_AMA_REQUESTS_GEN: MANAGE_ORGANIZATION_AMA_REQUESTS_GEN,
  MANAGE_ORGANIZATION_AMA_REQUESTS: MANAGE_ORGANIZATION_AMA_REQUESTS,
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN:
    MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN,
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS: MANAGE_ORGANIZATION_SCHEDULED_STREAMS,
  SETUP_ORGANIZATION_STREAM_GEN: SETUP_ORGANIZATION_STREAM_GEN,
  SETUP_ORGANIZATION_STREAM: SETUP_ORGANIZATION_STREAM,
  SETUP_ORGANIZATION_AMA_GEN: SETUP_ORGANIZATION_AMA_GEN,
  SETUP_ORGANIZATION_AMA: SETUP_ORGANIZATION_AMA,
  MANAGE_ORGANIZATION_MERCH_GEN: MANAGE_ORGANIZATION_MERCH_GEN,
  MANAGE_ORGANIZATION_MERCH: MANAGE_ORGANIZATION_MERCH,
  MANAGE_ORGANIZATION_DESIGNS_GEN: MANAGE_ORGANIZATION_DESIGNS_GEN,
  MANAGE_ORGANIZATION_DESIGNS: MANAGE_ORGANIZATION_DESIGNS,
  MANAGE_ORGANIZATION_BIO_GEN: MANAGE_ORGANIZATION_BIO_GEN,
  MEDIA_FEED_ORGANIZATION_GEN: MEDIA_FEED_ORGANIZATION_GEN,
  MEDIA_FEED_ORGANIZATION: MEDIA_FEED_ORGANIZATION,
  MANAGE_ORGANIZATION_BIO: MANAGE_ORGANIZATION_BIO,
  ORGANIZATION_STORE_SALES_GEN: ORGANIZATION_STORE_SALES_GEN,
  ORGANIZATION_STORE_SALES: ORGANIZATION_STORE_SALES,

  MANAGE_CONTENT_CREATOR_STORES: MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION,
  CONTENT_CREATOR_DASHBOARD_GEN: CONTENT_CREATOR_DASHBOARD_GEN,
  CONTENT_CREATOR_DASHBOARD: CONTENT_CREATOR_DASHBOARD,
  MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN:
    MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN,
  MANAGE_CONTENT_CREATOR_AMA_REQUESTS: MANAGE_CONTENT_CREATOR_AMA_REQUESTS,
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN:
    MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN,
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS:
    MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS,
  SETUP_CONTENT_CREATOR_STREAM_GEN: SETUP_CONTENT_CREATOR_STREAM_GEN,
  SETUP_CONTENT_CREATOR_STREAM: SETUP_CONTENT_CREATOR_STREAM,
  SETUP_CONTENT_CREATOR_AMA_GEN: SETUP_CONTENT_CREATOR_AMA_GEN,
  SETUP_CONTENT_CREATOR_AMA: SETUP_CONTENT_CREATOR_AMA,
  MANAGE_CONTENT_CREATOR_MERCH_GEN: MANAGE_CONTENT_CREATOR_MERCH_GEN,
  MANAGE_CONTENT_CREATOR_MERCH: MANAGE_CONTENT_CREATOR_MERCH,
  MANAGE_CONTENT_CREATOR_DESIGNS_GEN: MANAGE_CONTENT_CREATOR_DESIGNS_GEN,
  MANAGE_CONTENT_CREATOR_DESIGNS: MANAGE_CONTENT_CREATOR_DESIGNS,
  MANAGE_CONTENT_CREATOR_BIO_GEN: MANAGE_CONTENT_CREATOR_BIO_GEN,
  MEDIA_FEED_CONTENT_CREATOR_GEN: MEDIA_FEED_CONTENT_CREATOR_GEN,
  MEDIA_FEED_CONTENT_CREATOR: MEDIA_FEED_CONTENT_CREATOR,
  MANAGE_CONTENT_CREATOR_BIO: MANAGE_CONTENT_CREATOR_BIO,
  CONTENT_CREATOR_STORE_SALES_GEN: CONTENT_CREATOR_STORE_SALES_GEN,
  CONTENT_CREATOR_STORE_SALES: CONTENT_CREATOR_STORE_SALES,

  CUSTOMERS: CUSTOMERS_WITH_PAGINATION,
  CUSTOMER_PURCHASES_GEN: CUSTOMER_PURCHASES_GEN,
  CUSTOMER_PURCHASES: CUSTOMER_PURCHASES,
  CUSTOMER_SETTINGS_GEN: CUSTOMER_SETTINGS_GEN,
  CUSTOMER_SETTINGS: CUSTOMER_SETTINGS,
  CUSTOMER_ADDRESSES_GEN: CUSTOMER_ADDRESSES_GEN,
  CUSTOMER_ADDRESSES: CUSTOMER_ADDRESSES,
  CUSTOMER_PAYMENT_GEN: CUSTOMER_PAYMENT_GEN,
  CUSTOMER_PAYMENT: CUSTOMER_PAYMENT,

  ALL_PRODUCT_MEMORABILIA: ALL_PRODUCT_MEMORABILIA,

  ALL_AMA_REQUESTS: ALL_AMA_REQUESTS,

  STREAMS_CALENDAR: STREAMS_CALENDAR,
  ALL_STREAMS_TABLE: ALL_STREAMS_TABLE,
  MANAGE_WATCH_STREAM_MODERATORS_GEN: MANAGE_WATCH_STREAM_MODERATORS_GEN,
  WATCH_STREAM_GEN: WATCH_STREAM_GEN,
  WATCH_STREAM: WATCH_STREAM,

  PROMO_CODES: PROMO_CODES,
  BULK_UPLOAD: BULK_UPLOAD,
  DESIGN_REQUESTS: DESIGN_REQUESTS,
  DESIGNERS: DESIGNERS,
  DESIGN_REQUEST_DETAILS_GEN: DESIGN_REQUEST_DETAILS_GEN,
  DESIGN_REQUEST_RESULT_DETAILS_GEN: DESIGN_REQUEST_RESULT_DETAILS_GEN,
  DESIGN_REQUEST_DETAILS: DESIGN_REQUEST_DETAILS,
  DESIGN_REQUEST_RESULT_DETAILS: DESIGN_REQUEST_RESULT_DETAILS,

  EDITING_REQUESTS: EDITING_REQUESTS,
  VIDEO_EDITORS: VIDEO_EDITORS,
  VIDEO_EDIT_REQUEST_SETTINGS: VIDEO_EDIT_REQUEST_SETTINGS,

  MERCH_SALES_REPORT: MERCH_SALES_REPORT,
  AMA_SALES_REPORT: AMA_SALES_REPORT,
  STREAM_SALES_REPORT: STREAM_SALES_REPORT,

  GENERAL_SALES: GENERAL_SALES,
  EXPERIENCE_SALES: EXPERIENCE_SALES,
  MANAGE_MESSAGES: MANAGE_MESSAGES_WITH_PAGINATION,
  CAMPAIGNS: CAMPAIGNS,
  ADMINS: ADMINS,
  ACCOUNT: ACCOUNT,

  REDIRECTS: REDIRECTS,

  MANUAL_MERCH_MERGE: MANUAL_MERCH_MERGE,

  INTERVIEWS: INTERVIEWS,
  INTERVIEWERS: INTERVIEWERS,
  INTERVIEW_SETTINGS: INTERVIEW_SETTINGS,

  ARTICLES_WITH_PAGINATION: ARTICLES_WITH_PAGINATION,
};

const INTERVIEWER_ROUTE_ITEMS: Record<string, string> = {
  MY_INTERVIEWS: MY_INTERVIEWS,
  MY_SCHEDULED_EVENTS: MY_SCHEDULED_EVENTS,
  ACCOUNT: ACCOUNT,
  MY_AVAILABILITY: MY_AVAILABILITY,
};

const DESIGNER_ROUTE_ITEMS: Record<string, string> = {
  MY_DESIGN_REQUESTS: MY_DESIGN_REQUESTS,
  ACCOUNT: ACCOUNT,
  DESIGN_REQUEST_DETAILS_GEN: DESIGN_REQUEST_DETAILS_GEN,
  DESIGN_REQUEST_RESULT_DETAILS_GEN: DESIGN_REQUEST_RESULT_DETAILS_GEN,
  DESIGN_REQUEST_DETAILS: DESIGN_REQUEST_DETAILS,
  DESIGN_REQUEST_RESULT_DETAILS: DESIGN_REQUEST_RESULT_DETAILS,
  MANAGE_ATHLETE_MERCH: MANAGE_ATHLETE_MERCH,
  MANAGE_ATHLETE_MERCH_GEN: MANAGE_ATHLETE_MERCH_GEN,

  MANAGE_ORGANIZATION_MERCH: MANAGE_ORGANIZATION_MERCH,
  MANAGE_ORGANIZATION_MERCH_GEN: MANAGE_ORGANIZATION_MERCH_GEN,

  MANAGE_CONTENT_CREATOR_MERCH: MANAGE_CONTENT_CREATOR_MERCH,
  MANAGE_CONTENT_CREATOR_MERCH_GEN: MANAGE_CONTENT_CREATOR_MERCH_GEN,

  MANAGE_MERCH_TSHIRT: MANAGE_MERCH_TSHIRT,
  MANAGE_MERCH_HOODIE: MANAGE_MERCH_HOODIE,
  MANAGE_MERCH_HAT: MANAGE_MERCH_HAT,
  MANAGE_MERCH_JOGGERS: MANAGE_MERCH_JOGGERS,
  MANAGE_MERCH_RASH_GUARD: MANAGE_MERCH_RASH_GUARD,
};

const MODERATOR_ROUTE_ITEMS: Record<string, string> = {
  LIST_OF_ASSIGNED_STREAMS: LIST_OF_ASSIGNED_STREAMS,
  WATCH_STREAM: WATCH_STREAM,
  ACCOUNT: ACCOUNT,
};

const VIDEO_EDITOR_ROUTE_ITEMS: Record<string, string> = {
  MY_EDITING_REQUESTS: MY_EDITING_REQUESTS,
  VIDEO_EDITOR_PROFILE: VIDEO_EDITOR_PROFILE,
  ACCOUNT: ACCOUNT,
};

const ADMIN_ROUTE_ITEMS_COMPONENTS: Record<string, React.FC> = {
  [ADMIN_ROUTE_ITEMS.HOME_PAGE_MANAGEMENT]: HomePageManagement,
  [ADMIN_ROUTE_ITEMS.DASHBOARD]: Dashboard,
  [ADMIN_ROUTE_ITEMS.TAGS]: ManageTags,
  [ADMIN_ROUTE_ITEMS.MANAGE_MESSAGES]: ManageMessages,
  [ADMIN_ROUTE_ITEMS.CAMPAIGNS]: Campaigns,

  // [ADMIN_ROUTE_ITEMS.MANAGE_MERCH_GEN]: ManageMerch,
  // [ADMIN_ROUTE_ITEMS.MANAGE_MERCH]: ManageMerch,
  [ADMIN_ROUTE_ITEMS.MANAGE_MERCH_TSHIRT]: MerchConstructor,
  [ADMIN_ROUTE_ITEMS.MANAGE_MERCH_HOODIE]: MerchConstructor,
  [ADMIN_ROUTE_ITEMS.MANAGE_MERCH_HAT]: MerchConstructor,
  [ADMIN_ROUTE_ITEMS.MANAGE_MERCH_JOGGERS]: MerchConstructor,
  [ADMIN_ROUTE_ITEMS.MANAGE_MERCH_RASH_GUARD]: MerchConstructor,

  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETES_STORES]: ManageAthletesStores,
  [ADMIN_ROUTE_ITEMS.ATHLETE_DASHBOARD_GEN]: Dashboard,
  [ADMIN_ROUTE_ITEMS.ATHLETE_DASHBOARD]: Dashboard,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_AMA_REQUESTS_GEN]: ManageAthleteAmaRequests,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_AMA_REQUESTS]: ManageAthleteAmaRequests,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN]:
    ManageAthleteScheduledStreams,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_SCHEDULED_STREAMS]:
    ManageAthleteScheduledStreams,
  [ADMIN_ROUTE_ITEMS.SETUP_STREAM]: SetupAthleteStream,
  [ADMIN_ROUTE_ITEMS.SETUP_ATHLETE_STREAM]: SetupAthleteStream,
  [ADMIN_ROUTE_ITEMS.SETUP_AMA]: SetupAthleteAma,
  [ADMIN_ROUTE_ITEMS.SETUP_ATHLETE_AMA]: SetupAthleteAma,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_MERCH_GEN]: ManageAthleteMerch,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_MERCH]: ManageAthleteMerch,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_PRODUCTS_GEN]: ManageAthleteProducts,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_PRODUCTS]: ManageAthleteProducts,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_EXPERIENCE_GEN]: ManageAthleteExperience,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_EXPERIENCE]: ManageAthleteExperience,

  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_PRODUCTS_GEN]:
    ManageOrganizationProducts,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_PRODUCTS]: ManageOrganizationProducts,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_EXPERIENCE]:
    ManageOrganizationExperience,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_EXPERIENCE_GEN]:
    ManageOrganizationExperience,

  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_PRODUCTS_GEN]:
    ManageContentCreatorProducts,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_PRODUCTS]:
    ManageContentCreatorProducts,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_EXPERIENCE]:
    ManageContentCreatorExperience,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN]:
    ManageContentCreatorExperience,

  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_DESIGNS_GEN]: ManageAthleteDesigns,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_DESIGNS]: ManageAthleteDesigns,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_BIO_GEN]: ManageAthleteBio,
  [ADMIN_ROUTE_ITEMS.MANAGE_ATHLETE_BIO]: ManageAthleteBio,
  [ADMIN_ROUTE_ITEMS.MEDIA_FEED_ATHLETE_GEN]: ManageMediaFeed,
  [ADMIN_ROUTE_ITEMS.MEDIA_FEED_ATHLETE]: ManageMediaFeed,
  [ADMIN_ROUTE_ITEMS.ATHLETE_STORE_SALES_GEN]: AthleteStoreSales,
  [ADMIN_ROUTE_ITEMS.ATHLETE_STORE_SALES]: AthleteStoreSales,

  [ADMIN_ROUTE_ITEMS.ATHLETE_SCHEDULED_EVENTS_GEN]: ScheduledEvents,
  [ADMIN_ROUTE_ITEMS.ATHLETE_SCHEDULED_EVENTS]: ScheduledEvents,

  [ADMIN_ROUTE_ITEMS.ORGANIZATION_SCHEDULED_EVENTS_GEN]: ScheduledEvents,
  [ADMIN_ROUTE_ITEMS.ORGANIZATION_SCHEDULED_EVENTS]: ScheduledEvents,

  [ADMIN_ROUTE_ITEMS.CONTENT_CREATOR_SCHEDULED_EVENTS_GEN]: ScheduledEvents,
  [ADMIN_ROUTE_ITEMS.CONTENT_CREATOR_SCHEDULED_EVENTS]: ScheduledEvents,

  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_STORES]: ManageOrganizationsStores,
  [ADMIN_ROUTE_ITEMS.ORGANIZATION_DASHBOARD_GEN]: Dashboard,
  [ADMIN_ROUTE_ITEMS.ORGANIZATION_DASHBOARD]: Dashboard,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_AMA_REQUESTS_GEN]:
    ManageOrganizationAmaRequests,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_AMA_REQUESTS]:
    ManageOrganizationAmaRequests,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN]:
    ManageOrganizationScheduledStreams,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_SCHEDULED_STREAMS]:
    ManageOrganizationScheduledStreams,
  [ADMIN_ROUTE_ITEMS.SETUP_ORGANIZATION_STREAM_GEN]: SetupOrganizationStream,
  [ADMIN_ROUTE_ITEMS.SETUP_ORGANIZATION_STREAM]: SetupOrganizationStream,
  [ADMIN_ROUTE_ITEMS.SETUP_ORGANIZATION_AMA_GEN]: SetupOrganizationAma,
  [ADMIN_ROUTE_ITEMS.SETUP_ORGANIZATION_AMA]: SetupOrganizationAma,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_MERCH_GEN]: ManageOrganizationMerch,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_MERCH]: ManageOrganizationMerch,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_DESIGNS_GEN]:
    ManageOrganizationDesigns,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_DESIGNS]: ManageOrganizationDesigns,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_BIO_GEN]: ManageOrganizationBio,
  [ADMIN_ROUTE_ITEMS.MANAGE_ORGANIZATION_BIO]: ManageOrganizationBio,
  [ADMIN_ROUTE_ITEMS.MEDIA_FEED_ORGANIZATION_GEN]: ManageMediaFeed,
  [ADMIN_ROUTE_ITEMS.MEDIA_FEED_ORGANIZATION]: ManageMediaFeed,
  [ADMIN_ROUTE_ITEMS.ORGANIZATION_STORE_SALES_GEN]: OrganizationStoreSales,
  [ADMIN_ROUTE_ITEMS.ORGANIZATION_STORE_SALES]: OrganizationStoreSales,

  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_STORES]:
    ManageContentCreatorsStores,
  [ADMIN_ROUTE_ITEMS.CONTENT_CREATOR_DASHBOARD_GEN]: Dashboard,
  [ADMIN_ROUTE_ITEMS.CONTENT_CREATOR_DASHBOARD]: Dashboard,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN]:
    ManageContentCreatorAmaRequests,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_AMA_REQUESTS]:
    ManageContentCreatorAmaRequests,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN]:
    ManageContentCreatorScheduledStreams,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS]:
    ManageContentCreatorScheduledStreams,
  [ADMIN_ROUTE_ITEMS.SETUP_CONTENT_CREATOR_STREAM_GEN]:
    SetupContentCreatorStream,
  [ADMIN_ROUTE_ITEMS.SETUP_CONTENT_CREATOR_STREAM]: SetupContentCreatorStream,
  [ADMIN_ROUTE_ITEMS.SETUP_CONTENT_CREATOR_AMA_GEN]: SetupContentCreatorAma,
  [ADMIN_ROUTE_ITEMS.SETUP_CONTENT_CREATOR_AMA]: SetupContentCreatorAma,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_MERCH_GEN]:
    ManageContentCreatorMerch,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_MERCH]: ManageContentCreatorMerch,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_DESIGNS_GEN]:
    ManageContentCreatorDesigns,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_DESIGNS]:
    ManageContentCreatorDesigns,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_BIO_GEN]: ManageContentCreatorBio,
  [ADMIN_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_BIO]: ManageContentCreatorBio,
  [ADMIN_ROUTE_ITEMS.MEDIA_FEED_CONTENT_CREATOR_GEN]: ManageMediaFeed,
  [ADMIN_ROUTE_ITEMS.MEDIA_FEED_CONTENT_CREATOR]: ManageMediaFeed,
  [ADMIN_ROUTE_ITEMS.CONTENT_CREATOR_STORE_SALES_GEN]: ContentCreatorStoreSales,
  [ADMIN_ROUTE_ITEMS.CONTENT_CREATOR_STORE_SALES]: ContentCreatorStoreSales,

  [ADMIN_ROUTE_ITEMS.CUSTOMERS]: Customers,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_PURCHASES_GEN]: CustomerPurchases,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_PURCHASES]: CustomerPurchases,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_SETTINGS_GEN]: CustomerSettings,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_SETTINGS]: CustomerSettings,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_ADDRESSES_GEN]: CustomerAddresses,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_ADDRESSES]: CustomerAddresses,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_PAYMENT_GEN]: CustomerPayment,
  [ADMIN_ROUTE_ITEMS.CUSTOMER_PAYMENT]: CustomerPayment,

  [ADMIN_ROUTE_ITEMS.ALL_PRODUCT_MEMORABILIA]: AllProductMemorabilia,

  [ADMIN_ROUTE_ITEMS.ALL_AMA_REQUESTS]: AllAmaRequests,

  [ADMIN_ROUTE_ITEMS.STREAMS_CALENDAR]: StreamsCalendar,
  [ADMIN_ROUTE_ITEMS.ALL_STREAMS_TABLE]: AllStreams,
  [MANAGE_WATCH_STREAM_MODERATORS_GEN]: Moderators,

  [ADMIN_ROUTE_ITEMS.WATCH_STREAM_GEN]: WatchStream,
  [ADMIN_ROUTE_ITEMS.WATCH_STREAM]: WatchStream,

  [ADMIN_ROUTE_ITEMS.PROMO_CODES]: PromoCodes,
  [ADMIN_ROUTE_ITEMS.BULK_UPLOAD]: BulkUpload,

  [ADMIN_ROUTE_ITEMS.INTERVIEWERS]: Interviewers,
  [ADMIN_ROUTE_ITEMS.INTERVIEWS]: Interviews,
  [ADMIN_ROUTE_ITEMS.INTERVIEW_SETTINGS]: InterviewSettings,

  [ADMIN_ROUTE_ITEMS.DESIGN_REQUESTS]: DesignRequests,
  [ADMIN_ROUTE_ITEMS.DESIGNERS]: DesignersPage,
  [ADMIN_ROUTE_ITEMS.DESIGN_REQUEST_DETAILS_GEN]: DesignRequestDetails,
  [ADMIN_ROUTE_ITEMS.DESIGN_REQUEST_RESULT_DETAILS_GEN]: DesignResultDetails,
  [ADMIN_ROUTE_ITEMS.DESIGN_REQUEST_DETAILS]: DesignRequestDetails,
  [ADMIN_ROUTE_ITEMS.DESIGN_REQUEST_RESULT_DETAILS]: DesignResultDetails,

  [ADMIN_ROUTE_ITEMS.EDITING_REQUESTS]: VideoEditingRequestPage,
  [ADMIN_ROUTE_ITEMS.VIDEO_EDITORS]: VideoEditorsPage,
  [ADMIN_ROUTE_ITEMS.VIDEO_EDIT_REQUEST_SETTINGS]: VideoEditRequestSettings,

  [ADMIN_ROUTE_ITEMS.MERCH_SALES_REPORT]: AthleteSalesReport,
  [ADMIN_ROUTE_ITEMS.AMA_SALES_REPORT]: AmaSalesReport,
  [ADMIN_ROUTE_ITEMS.STREAM_SALES_REPORT]: StreamSalesReport,
  [ADMIN_ROUTE_ITEMS.GENERAL_SALES]: GeneralSales,
  [ADMIN_ROUTE_ITEMS.EXPERIENCE_SALES]: ExperienceSales,

  [ADMIN_ROUTE_ITEMS.ADMINS]: Admins,
  [ADMIN_ROUTE_ITEMS.ACCOUNT]: Account,

  [ADMIN_ROUTE_ITEMS.REDIRECTS]: Redirects,

  [ADMIN_ROUTE_ITEMS.MANUAL_MERCH_MERGE]: ManualMerchMerge,
  [ADMIN_ROUTE_ITEMS.ARTICLES_WITH_PAGINATION]: Articles,
};

const INTERVIEWER_ROUTE_ITEMS_COMPONENTS: Record<string, React.FC> = {
  [INTERVIEWER_ROUTE_ITEMS.MY_INTERVIEWS]: MyInterviews,
  [INTERVIEWER_ROUTE_ITEMS.MY_SCHEDULED_EVENTS]: MyScheduledEvents,
  [INTERVIEWER_ROUTE_ITEMS.MY_AVAILABILITY]: MyAvailability,
  [INTERVIEWER_ROUTE_ITEMS.ACCOUNT]: Account,
};

const DESIGNER_ROUTE_ITEMS_COMPONENTS: Record<string, React.FC> = {
  [DESIGNER_ROUTE_ITEMS.MY_DESIGN_REQUESTS]: MyDesignRequests,
  [DESIGNER_ROUTE_ITEMS.DESIGN_REQUEST_DETAILS_GEN]: DesignRequestDetails,
  [DESIGNER_ROUTE_ITEMS.DESIGN_REQUEST_RESULT_DETAILS_GEN]: DesignResultDetails,
  [DESIGNER_ROUTE_ITEMS.DESIGN_REQUEST_DETAILS]: DesignRequestDetails,
  [DESIGNER_ROUTE_ITEMS.DESIGN_REQUEST_RESULT_DETAILS]: DesignResultDetails,
  [DESIGNER_ROUTE_ITEMS.ACCOUNT]: Account,
  [DESIGNER_ROUTE_ITEMS.MANAGE_ATHLETE_MERCH]: ManageAthleteMerch,
  [DESIGNER_ROUTE_ITEMS.MANAGE_ATHLETE_MERCH_GEN]: ManageAthleteMerch,

  [DESIGNER_ROUTE_ITEMS.MANAGE_ORGANIZATION_MERCH]: ManageOrganizationMerch,
  [DESIGNER_ROUTE_ITEMS.MANAGE_ORGANIZATION_MERCH_GEN]: ManageOrganizationMerch,

  [DESIGNER_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_MERCH]:
    ManageContentCreatorMerch,
  [DESIGNER_ROUTE_ITEMS.MANAGE_CONTENT_CREATOR_MERCH_GEN]:
    ManageContentCreatorMerch,

  [DESIGNER_ROUTE_ITEMS.MANAGE_MERCH_TSHIRT]: MerchConstructor,
  [DESIGNER_ROUTE_ITEMS.MANAGE_MERCH_HOODIE]: MerchConstructor,
  [DESIGNER_ROUTE_ITEMS.MANAGE_MERCH_HAT]: MerchConstructor,
  [DESIGNER_ROUTE_ITEMS.MANAGE_MERCH_JOGGERS]: MerchConstructor,
  [DESIGNER_ROUTE_ITEMS.MANAGE_MERCH_RASH_GUARD]: MerchConstructor,
};

const MODERATOR_ROUTE_ITEMS_COMPONENTS: Record<string, React.FC> = {
  [MODERATOR_ROUTE_ITEMS.LIST_OF_ASSIGNED_STREAMS]: ListOfAssignedStreams,
  [MODERATOR_ROUTE_ITEMS.WATCH_STREAM]: WatchStream,
  [MODERATOR_ROUTE_ITEMS.ACCOUNT]: Account,
};

const VIDEO_EDITOR_ROUTE_ITEMS_COMPONENTS: Record<string, React.FC> = {
  [VIDEO_EDITOR_ROUTE_ITEMS.MY_EDITING_REQUESTS]: MyVideoEditRequests,
  [VIDEO_EDITOR_ROUTE_ITEMS.VIDEO_EDITOR_PROFILE]: VideoEditorProfile,
  [VIDEO_EDITOR_ROUTE_ITEMS.ACCOUNT]: Account,
};

const MainRoutes: React.FC<{
  isDesigner: boolean;
  isModerator: boolean;
  isInterviewer: boolean;
  isVideoEditor: boolean;
}> = ({ isDesigner, isModerator, isInterviewer, isVideoEditor }) => {
  const match = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match]);

  return (
    <LayoutWrapper>
      <Switch>
        <Route
          exact
          strict
          path={match.path}
          component={
            !isDesigner && isModerator
              ? MODERATOR_ROUTE_ITEMS_COMPONENTS[match.path]
              : isDesigner
              ? DESIGNER_ROUTE_ITEMS_COMPONENTS[match.path]
              : isVideoEditor
              ? VIDEO_EDITOR_ROUTE_ITEMS_COMPONENTS[match.path]
              : isInterviewer
              ? INTERVIEWER_ROUTE_ITEMS_COMPONENTS[match.path]
              : ADMIN_ROUTE_ITEMS_COMPONENTS[match.path]
          }
        />
      </Switch>
    </LayoutWrapper>
  );
};

const NotFoundRoute: React.FC<{
  isDesigner: boolean;
  isModerator: boolean;
  isVideoEditor: boolean;
  isInterviewer: boolean;
}> = ({ isDesigner, isModerator, isVideoEditor, isInterviewer }) => {
  const match = useRouteMatch();
  const location = useLocation();

  let noSuchRoute =
    isModerator && !isDesigner
      ? !MODERATOR_ROUTE_ITEMS[match.path]
      : isDesigner
      ? !DESIGNER_ROUTE_ITEMS[match.path]
      : isVideoEditor
      ? !VIDEO_EDITOR_ROUTE_ITEMS[match.path]
      : isInterviewer
      ? !INTERVIEWER_ROUTE_ITEMS[match.path]
      : !ADMIN_ROUTE_ITEMS[match.path];

  const ROUTE_ITEMS_COMPONENTS =
    isModerator && !isDesigner
      ? MODERATOR_ROUTE_ITEMS_COMPONENTS
      : isDesigner
      ? DESIGNER_ROUTE_ITEMS_COMPONENTS
      : isVideoEditor
      ? VIDEO_EDITOR_ROUTE_ITEMS_COMPONENTS
      : isInterviewer
      ? INTERVIEWER_ROUTE_ITEMS_COMPONENTS
      : ADMIN_ROUTE_ITEMS_COMPONENTS;

  !ROUTE_ITEMS_COMPONENTS[match.path] &&
    Object.keys(ROUTE_ITEMS_COMPONENTS).map((key: string) =>
      key.includes(`/${location.pathname.split('/')[1]}`)
        ? (noSuchRoute = false)
        : key
    );

  return location.pathname === '/' ? (
    <Redirect
      to={
        isDesigner
          ? MY_DESIGN_REQUESTS
          : isVideoEditor
          ? MY_EDITING_REQUESTS
          : isModerator
          ? LIST_OF_ASSIGNED_STREAMS
          : isInterviewer
          ? MY_INTERVIEWS
          : DASHBOARD
      }
    />
  ) : noSuchRoute ? (
    <LayoutWrapper>
      <Route component={NotFound} />
    </LayoutWrapper>
  ) : null;
};

const httpLink = createUploadLink({
  uri: ENV.PUBLIC_API_HOST,
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const errorMessages: string[] = graphQLErrors.map(
        ({ message }) => message
      );

      if (errorMessages.length) {
        return handleAuthError({ operation, forward, errorMessages });
      }
    }

    if (networkError) {
      const errorMessage =
        typeof networkError === 'string'
          ? networkError
          : JSON.stringify(networkError);

      notification['error']({
        description:
          "Seems like You've lost the Internet connection. Try connecting a different Wi-Fi, or check the network cables, modem and router.",
        message: 'Network error',
        duration: 15,
        icon: <WifiOutlined style={{ color: '#f5222d' }} />,
      });

      console.log(`[Network error]: ${errorMessage}`);
    }
  }
);

const authLink = new ApolloLink((operation, forward) => {
  const authUserFromCookies = getAuthUserFromCookies();
  const tokenFromCookies = authUserFromCookies?.accessToken || '';

  operation.setContext(({ headers: operationHeaders = {} }) => {
    return {
      headers: {
        ...operationHeaders,
        authorization: tokenFromCookies ? `Bearer ${tokenFromCookies}` : '',
      },
    };
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: from([errorLink, authLink]).concat(httpLink),
});

const App: React.FC = () => {
  const { authUser } = useAppContext();

  const isDesigner = authUser?.role === UserRole.Designer;
  const isModerator = authUser?.role === UserRole.Moderator;
  const isVideoEditor = authUser?.role === UserRole.VideoEditor;
  const isInterviewer = authUser?.isInterviewer || false;

  const routeItems =
    isModerator && !isDesigner
      ? MODERATOR_ROUTE_ITEMS
      : isDesigner
      ? DESIGNER_ROUTE_ITEMS
      : isVideoEditor
      ? VIDEO_EDITOR_ROUTE_ITEMS
      : isInterviewer
      ? INTERVIEWER_ROUTE_ITEMS
      : ADMIN_ROUTE_ITEMS;

  return (
    <ApolloProvider client={client}>
      <CookieTokenWatcher />

      <Router>
        <Switch>
          {Object.keys(routeItems).map((route) => (
            <Route
              exact
              strict
              key={routeItems[route]}
              path={routeItems[route]}
            >
              <MainRoutes
                isDesigner={isDesigner}
                isModerator={isModerator}
                isVideoEditor={isVideoEditor}
                isInterviewer={isInterviewer}
              />
            </Route>
          ))}

          <Route path={LOGIN} exact component={Login} />
          <NotFoundRoute
            isDesigner={isDesigner}
            isModerator={isModerator}
            isVideoEditor={isVideoEditor}
            isInterviewer={isInterviewer}
          />
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default App;
