import React, { useEffect, useState } from 'react';
import env from 'api/env';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Spin, Typography } from 'antd';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
import { uploadImages } from 'helpers/interview';
// Api
import { GET_INTERVIEW } from 'api/interview/queries';
import {
  CREATE_STREAM_IMAGE,
  UPDATE_INTERVIEW_INFO,
} from 'api/interview/mutations';
// Types
import {
  CreateStreamImage,
  CreateStreamImageVariables,
} from 'api/interview/types/CreateStreamImage';
import { InterviewStatus } from 'api/graphql-global-types';
import {
  UpdateInterviewInfo,
  UpdateInterviewInfoVariables,
} from 'api/interview/types/UpdateInterviewInfo';
import {
  GetInterview,
  GetInterviewVariables,
} from 'api/interview/types/GetInterview';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import CopyLink from 'ui/CopyLink/CopyLink';
// Components
import CroppedPictureInput from 'components/common/CroppedPictureInput/CroppedPictureInput';

const { Text } = Typography;

type UpdateInfoModalProps = {
  interviewId: string;
  hideUpdateInfoModal: () => void;
};

const UpdateInfoModal = ({
  interviewId,
  hideUpdateInfoModal,
}: UpdateInfoModalProps): JSX.Element | null => {
  const [, setPictureValidation] = useState<string>('');
  const { data, loading } = useQuery<GetInterview, GetInterviewVariables>(
    GET_INTERVIEW,
    {
      variables: {
        input: {
          id: interviewId,
        },
      },
      skip: !interviewId,
      fetchPolicy: 'cache-and-network',
    }
  );

  const [createStreamImage] = useMutation<
    CreateStreamImage,
    CreateStreamImageVariables
  >(CREATE_STREAM_IMAGE);

  const [updateInterviewInfo, { loading: updateInterviewInfoLoading }] =
    useMutation<UpdateInterviewInfo, UpdateInterviewInfoVariables>(
      UPDATE_INTERVIEW_INFO,
      {
        refetchQueries: [
          {
            query: GET_INTERVIEW,
            variables: {
              input: {
                id: interviewId,
              },
            },
          },
        ],
      }
    );

  const interview = data?.getInterview || null;
  const isScheduled = data?.getInterview?.status === InterviewStatus.Scheduled;
  const isCompleted = data?.getInterview?.status === InterviewStatus.Completed;
  const isCanceled = data?.getInterview?.status === InterviewStatus.Canceled;
  const isCanceledOrCompleted = isCanceled || isCompleted;

  const [form] = Form.useForm();

  const handleFormFinish = async (values: any) => {
    const shouldUpload = Boolean(Array.isArray(values.streamImage));
    if (shouldUpload) {
      const contentType = values?.streamImage[0]?.type;
      const ext = contentType?.split('/')[1];
      const interviewerId = interview?.interviewer?.id as string;
      try {
        if (ext && contentType && interviewerId) {
          const { data } = await createStreamImage({
            variables: {
              input: {
                files: [
                  {
                    contentType,
                    ext,
                  },
                ],
              },
            },
          });

          if (data) {
            const imagesUrls = await uploadImages(
              data?.createStreamImage,
              values.streamImage
            );

            await updateInterviewInfo({
              variables: {
                input: {
                  id: interviewId,
                  notes: form.getFieldValue('notes'),
                  scheduledMeetingLink: form.getFieldValue(
                    'scheduledMeetingLink'
                  ),
                  name: values.streamName ? values.streamName : null,
                  imageFileKey: imagesUrls[0],
                },
              },
            });
            successNotification('Information updated successfully');
            hideUpdateInfoModal();
          }
        }
      } catch (err) {
        errorNotification((err as Error)?.message || 'Something went wrong');
      }
    } else {
      try {
        await updateInterviewInfo({
          variables: {
            input: {
              id: interviewId,
              notes: form.getFieldValue('notes'),
              scheduledMeetingLink: form.getFieldValue('scheduledMeetingLink'),
              name: values.streamName ? values.streamName : null,
            },
          },
        });

        successNotification('Information updated successfully');
        hideUpdateInfoModal();
      } catch (err) {
        errorNotification((err as Error)?.message || 'Something went wrong');
      }
    }
  };

  const handleStartStream = () => {
    window.open(
      `${getEnvLink(env.REACT_APP_ENV)}/${
        interview?.interviewer?.slug
      }/start-stream/${interview?.stream?.slug}`,
      '_blank'
    );
  };

  useEffect(() => {
    if (interview) {
      form.setFieldsValue({
        notes: interview.notes,
        scheduledMeetingLink: interview.scheduledMeetingLink,
        streamName: interview.stream?.name,
        streamImage: interview.stream?.mainImageUrl,
      });
    }
  }, [interview, form]);

  if (loading || updateInterviewInfoLoading) {
    return <Spin />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <div>
        <p>
          <strong>Task ID:</strong> {interview?.id || ''}
        </p>
      </div>

      <div>
        <p>
          <strong>Account: </strong>
          <a
            href={`${getEnvLink(env.REACT_APP_ENV)}/${
              interview?.interviewee?.slug || ''
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {interview?.interviewee?.firstName || ''}{' '}
            {interview?.interviewee?.lastName || ''}
          </a>
        </p>
      </div>

      <div>
        <p>
          <strong>Status:</strong> {interview?.status || ''}
        </p>
      </div>

      {!isCanceledOrCompleted && (
        <CopyLink
          name="schedule-url"
          label="Schedule interview link"
          link={`${getEnvLink(env.REACT_APP_ENV)}/schedule-interview/${
            interview?.id
          }`}
        />
      )}

      {isScheduled && (
        <>
          {interview?.stream?.slug && (
            <>
              <CopyLink
                name="stream-url"
                label="Start Stream link"
                link={`${getEnvLink(env.REACT_APP_ENV)}/${
                  interview?.interviewer?.slug
                }/start-stream/${interview?.stream?.slug}`}
              />

              <Button onClick={handleStartStream} danger>
                Start interview
              </Button>
            </>
          )}

          {interview?.streamCohostToken && (
            <CopyLink
              name="guest"
              label="Link for guest to join"
              link={`${getEnvLink(env.REACT_APP_ENV)}/guest/${
                interview?.streamCohostToken
              }`}
            />
          )}
        </>
      )}

      <div>
        <p>
          <strong>Admin notes:</strong> {interview?.adminNotes || ''}
        </p>
      </div>

      <Form
        form={form}
        layout="horizontal"
        name="sendInviteForm"
        autoComplete="off"
        onFinish={handleFormFinish}
      >
        <Form.Item name="notes" label={<Text>My Notes</Text>}>
          <Input placeholder="Add notes here" />
        </Form.Item>

        {interview?.status === InterviewStatus.Invited ||
        interview?.status === InterviewStatus.InviteViewed ? (
          <></>
        ) : (
          <>
            <Form.Item name="streamName" label={<Text>Stream Title</Text>}>
              <Input />
            </Form.Item>

            <Form.Item name="streamImage" label="Stream Cover Image">
              <CroppedPictureInput
                setPictureValidation={setPictureValidation}
                defaultImage={interview?.stream?.mainImageUrl || null}
                buttonTitle="Upload stream cover image"
              />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={updateInterviewInfoLoading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateInfoModal;
