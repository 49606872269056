import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
// Hooks
import {
  useGetUserTypeFromRoute,
  useDefaultStoreRedirectOnEmptyId,
} from 'hooks';
// Api
import { CREATE_STREAM_ADMIN } from 'api/streams/mutations';
import { GET_MODERATORS } from 'api/streams/queries';
// Types
import {
  CreateStreamAdmin,
  CreateStreamAdminVariables,
} from 'api/streams/types/CreateStreamAdmin';
import { GetModerators } from 'api/streams/types/GetModerators';
// Helpers
import { getTimeZoneName, getCurrentTimeZoneOffset } from 'helpers/timeZone';
import { formatHashtagInput } from 'helpers/hashtags';
// Routes
import {
  MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN,
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN,
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN,
} from 'constants/routes';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import { CreateEditStreamForm } from 'components/common/CreateEditStreamForm/CreateEditStreamForm';

const SetupStream = (): JSX.Element => {
  useDefaultStoreRedirectOnEmptyId();

  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>([]);

  const { isAthlete, isContentCreator, isOrganization } =
    useGetUserTypeFromRoute();
  const history = useHistory();
  const { storeId } = useParams<{ storeId: string }>();

  const { data } = useQuery<GetModerators>(GET_MODERATORS);

  const [createStream, { loading }] = useMutation<
    CreateStreamAdmin,
    CreateStreamAdminVariables
  >(CREATE_STREAM_ADMIN);

  const handleStreamCreate = async (values: any) => {
    const timeZoneOffset = momentTimezone(values.date)
      .tz(getTimeZoneName(values.tzCode))
      .utcOffset();

    const sponsorInput =
      !values?.sponsorImage || !values?.sponsorName
        ? []
        : [
            {
              name: values?.sponsorName,
              imageFile:
                process.env.NODE_ENV === 'test'
                  ? null
                  : values?.sponsorImage?.[0]?.originFileObj,
              promoMessage: values?.sponsorPromoMessage,
            },
          ];

    try {
      await createStream({
        variables: {
          input: {
            storeId,
            scheduleDate: moment(values.date)
              .add(getCurrentTimeZoneOffset(), 'minute')
              .startOf('minute')
              .toISOString(),
            timeZone: {
              tzCode: values.tzCode,
              offset: timeZoneOffset,
            },
            hashtagInputs: values?.hashtags?.length
              ? formatHashtagInput(values.hashtags)
              : null,
            image:
              process.env.NODE_ENV === 'test'
                ? null
                : values?.picture?.[0]?.originFileObj,
            name: values?.repeatsEveryEnum ? null : values?.name || '',
            repeatsEveryEnum: values?.repeatsEveryEnum,
            repeatingTitle: values?.repeatsEveryEnum ? values.name : null,
            requestedPrice: !values?.isFree ? values?.requestedPrice : null,
            isFree: Boolean(values?.isFree),
            isHidden: Boolean(values?.isHidden),
            description: values?.description || '',
            sponsors: sponsorInput,
            moderatorId: values?.moderatorId || null,
            affiliateProducts: values?.affiliateProducts || [],
            participantIds: selectedStoreIds,
          },
        },
      });

      successNotification('The stream has been created successfully');

      if (isAthlete) {
        history.push(`${MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN}/${storeId}`);
      } else if (isOrganization) {
        history.push(`${MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN}/${storeId}`);
      } else if (isContentCreator) {
        history.push(
          `${MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN}/${storeId}`
        );
      } else {
        history.push('/');
      }
    } catch (err: any) {
      errorNotification(
        `Something went wrong.${err?.message ? ` (${err?.message})` : ''}`
      );
    }
  };

  return (
    <CreateEditStreamForm
      selectedStoreIds={selectedStoreIds}
      setSelectedStoreIds={setSelectedStoreIds}
      onFinish={handleStreamCreate}
      loading={loading}
      submitButtonTitle="Create Stream"
      moderators={data?.moderators?.entities}
    />
  );
};

export default SetupStream;
