import { gql } from '@apollo/client';
// Fragments
import { InterviewFields } from './fragments';

export const GET_MY_INTERVIEWS = gql`
  query GetMyInterviews($input: GetMyInterviewsInput!) {
    getMyInterviews(input: $input) {
      entities {
        ...InterviewFields
      }
      limit
      offset
      total
    }
  }
  ${InterviewFields}
`;

export const GET_INTERVIEWS = gql`
  query GetInterviews($input: GetInterviewsInput!) {
    getInterviews(input: $input) {
      counters {
        Canceled
        Completed
        InviteViewed
        Invited
        ScheduleAttempted
        Scheduled
      }
      entities {
        ...InterviewFields
        auditLogs {
          id
          interviewerId
          type
          action
          createdAt
          interviewer {
            firstName
            lastName
            storeDetails {
              storeName
            }
          }
          performedBy {
            firstName
            lastName
          }
        }
      }
      limit
      offset
      total
    }
  }
  ${InterviewFields}
`;

export const GET_INTERVIEW = gql`
  query GetInterview($input: GetInterviewTaskInput!) {
    getInterview(input: $input) {
      ...InterviewFields
      stream {
        streamRecords {
          id
          streamId
          videoURL
          downloadVideoURL
        }
      }
    }
  }
  ${InterviewFields}
`;

export const SEARCH_MERCH_PRODUCTS = gql`
  query SearchMerchProducts($input: GetMerchProductsInput) {
    getMerchProducts(input: $input) {
      entities {
        id
        title
      }
    }
  }
`;

export const SEARCH_STREAMS = gql`
  query SearchStreams($input: StreamFilterInput!) {
    streamsV2(input: $input) {
      entities {
        id
        name
        store {
          id
          slug
          lastName
          firstName
          storeDetails {
            id
            storeName
          }
        }
      }
    }
  }
`;

export const SEARCH_MEMORABILIA = gql`
  query SearchMemorabilia($input: MemorabiliaFilterInput!) {
    getMemorabilia(input: $input) {
      entities {
        id
        title
      }
    }
  }
`;

export const GET_INTERVIEW_SETTINGS = gql`
  query GetInterviewSettings {
    getInterviewSettings {
      id
      ... on InterviewHighValueMerchTrigger {
        id
        isEnabled
        config {
          minPrice
        }
      }
      ... on InterviewHighValueMemorabiliaTrigger {
        id
        isEnabled
        config {
          minPrice
        }
      }
      ... on InterviewPPVParticipantTrigger {
        id
        isEnabled
      }
    }
  }
`;
