import React, { useEffect, useState } from 'react';
import env from 'api/env';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Select, Spin, Typography } from 'antd';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
import { uploadImages } from 'helpers/interview';
// Api
import { GET_INTERVIEW } from 'api/interview/queries';
import {
  CREATE_STREAM_IMAGE_ADMIN,
  UPDATE_INTERVIEW_INFO_ADMIN,
} from 'api/interview/mutations';
// Types
import {
  CreateStreamImageAdmin,
  CreateStreamImageAdminVariables,
} from 'api/interview/types/CreateStreamImageAdmin';
import {
  UpdateInterviewInfoAdmin,
  UpdateInterviewInfoAdminVariables,
} from 'api/interview/types/UpdateInterviewInfoAdmin';
import { InterviewSourceType, InterviewStatus } from 'api/graphql-global-types';
import {
  AutoCompleteOption,
  interviewSourceTypeOptions,
} from '../SendInviteModal/SendInviteModal';
import {
  GetInterview,
  GetInterviewVariables,
} from 'api/interview/types/GetInterview';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import CopyLink from 'ui/CopyLink/CopyLink';
// Components
import SearchStreamsComponent from '../SearchStreams/SearchStreams';
import SearchMerchComponent from '../SearchMerch/SearchMerch';
import SearchProductsComponent from '../SearchProducts/SearchProducts';
import CroppedPictureInput from 'components/common/CroppedPictureInput/CroppedPictureInput';

const { Text } = Typography;
const { Option } = Select;

type UpdateInfoAdminModalModalProps = {
  interviewId: string;
  hideUpdateInfoModal: () => void;
};

const UpdateInfoAdminModal = ({
  interviewId,
  hideUpdateInfoModal,
}: UpdateInfoAdminModalModalProps): JSX.Element | null => {
  const [, setPictureValidation] = useState<string>('');
  const [selectedInterviewType, setSelectedInterviewType] =
    useState<InterviewSourceType | null>();

  const { data, loading } = useQuery<GetInterview, GetInterviewVariables>(
    GET_INTERVIEW,
    {
      variables: {
        input: {
          id: interviewId,
        },
      },
      skip: !interviewId,
      fetchPolicy: 'cache-and-network',
    }
  );

  const [updateInterviewInfoAdmin, { loading: updateInterviewInfoLoading }] =
    useMutation<UpdateInterviewInfoAdmin, UpdateInterviewInfoAdminVariables>(
      UPDATE_INTERVIEW_INFO_ADMIN
    );

  const [createStreamImageAdmin] = useMutation<
    CreateStreamImageAdmin,
    CreateStreamImageAdminVariables
  >(CREATE_STREAM_IMAGE_ADMIN);

  const interview = data?.getInterview || null;
  const [form] = Form.useForm();

  const handleFormFinish = async (values: any) => {
    const shouldUpload = Boolean(Array.isArray(values.streamImage));

    if (shouldUpload) {
      const contentType = values?.streamImage[0]?.type;
      const ext = contentType?.split('/')[1];
      const interviewerId = interview?.interviewer?.id as string;
      try {
        if (ext && contentType && interviewerId) {
          const { data } = await createStreamImageAdmin({
            variables: {
              input: {
                storeId: interviewerId,
                files: [
                  {
                    contentType,
                    ext,
                  },
                ],
              },
            },
          });

          if (data) {
            const imagesUrls = await uploadImages(
              data?.createStreamImageAdmin,
              values.streamImage
            );

            const sourceInput = {
              type: values.interviewType,
              ...(values.interviewType === InterviewSourceType.Merch && {
                merchId: Number(selectedMerchOption?.value),
              }),
              ...(values.interviewType === InterviewSourceType.Product && {
                productId: selectedMerchOption?.value as string,
              }),
              ...(values.interviewType === InterviewSourceType.Memorabilia && {
                productId: selectedMerchOption?.value as string,
              }),
              ...(values.interviewType ===
                InterviewSourceType.PPVParticipant && {
                streamId: selectedStreamOption?.value as string,
              }),
            };

            await updateInterviewInfoAdmin({
              variables: {
                input: {
                  id: interviewId,
                  adminNotes: form.getFieldValue('adminNotes'),
                  scheduledMeetingLink: form.getFieldValue(
                    'scheduledMeetingLink'
                  ),
                  source: sourceInput,
                  name: values.streamName ? values.streamName : null,
                  imageFileKey: imagesUrls[0],
                },
              },
            });
            successNotification('Information updated successfully');
            hideUpdateInfoModal();
          }
        }
      } catch (err) {
        errorNotification((err as Error)?.message || 'Something went wrong');
      }
    } else {
      try {
        const sourceInput = {
          type: values.interviewType,
          ...(values.interviewType === InterviewSourceType.Merch && {
            merchId: Number(selectedMerchOption?.value),
          }),
          ...(values.interviewType === InterviewSourceType.Product && {
            productId: selectedMerchOption?.value as string,
          }),
          ...(values.interviewType === InterviewSourceType.Memorabilia && {
            productId: selectedMerchOption?.value as string,
          }),
          ...(values.interviewType === InterviewSourceType.PPVParticipant && {
            streamId: selectedStreamOption?.value as string,
          }),
        };

        await updateInterviewInfoAdmin({
          variables: {
            input: {
              id: interviewId,
              adminNotes: form.getFieldValue('adminNotes'),
              scheduledMeetingLink: form.getFieldValue('scheduledMeetingLink'),
              name: values.streamName ? values.streamName : null,
              source: sourceInput,
            },
          },
        });
        successNotification('Information updated successfully');
        hideUpdateInfoModal();
      } catch (err) {
        errorNotification((err as Error)?.message || 'Something went wrong');
      }
    }
  };

  const handleStartStream = () => {
    window.open(
      `${getEnvLink(env.REACT_APP_ENV)}/${
        interview?.interviewer?.slug
      }/start-stream/${interview?.stream?.slug}`,
      '_blank'
    );
  };

  useEffect(() => {
    if (interview) {
      form.setFieldsValue({
        adminNotes: interview.adminNotes,
        scheduledMeetingLink: interview.scheduledMeetingLink,
        interviewType: interview.source?.type,
        streamName: interview.stream?.name,
        streamImage: interview.stream?.mainImageUrl,
      });
    }
  }, [interview, form]);

  const storeId = interview?.interviewee?.id;
  const isCompleted = data?.getInterview?.status === InterviewStatus.Completed;
  const isCanceled = data?.getInterview?.status === InterviewStatus.Canceled;
  const isCanceledOrCompleted = isCanceled || isCompleted;

  const showSearchStream =
    storeId && selectedInterviewType === InterviewSourceType.PPVParticipant;
  const showSearchMerch =
    storeId && selectedInterviewType === InterviewSourceType.Merch;

  const isProductOrMemorabilia =
    selectedInterviewType === InterviewSourceType.Product ||
    selectedInterviewType === InterviewSourceType.Memorabilia;

  const showSearchProduct = storeId && isProductOrMemorabilia;
  const isScheduled = data?.getInterview?.status === InterviewStatus.Scheduled;

  const [selectedStreamOption, setSelectedStreamOption] =
    useState<AutoCompleteOption | null>(null);

  const [selectedMerchOption, setSelectedMerchOption] =
    useState<AutoCompleteOption | null>(null);

  const [selectedProductOption, setSelectedProductOption] =
    useState<AutoCompleteOption | null>(null);

  if (loading || updateInterviewInfoLoading) {
    return <Spin />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <div>
        <p>
          <strong>Task ID:</strong> {interview?.id || ''}
        </p>
      </div>

      <div>
        <p>
          <strong>Account: </strong>
          <a
            href={`${getEnvLink(env.REACT_APP_ENV)}/${
              interview?.interviewee?.slug || ''
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {interview?.interviewee?.firstName || ''}{' '}
            {interview?.interviewee?.lastName || ''}
          </a>
        </p>
      </div>

      <div>
        <p>
          <strong>Status:</strong> {interview?.status || ''}
        </p>
      </div>

      {!isCanceledOrCompleted && (
        <CopyLink
          name="schedule-url"
          label="Schedule interview link"
          link={`${getEnvLink(env.REACT_APP_ENV)}/schedule-interview/${
            interview?.id
          }`}
        />
      )}

      <>
        {isScheduled && (
          <>
            {interview?.stream?.slug && (
              <>
                <CopyLink
                  name="stream-url"
                  label="Start Stream link"
                  link={`${getEnvLink(env.REACT_APP_ENV)}/${
                    interview?.interviewer?.slug
                  }/start-stream/${interview?.stream?.slug}`}
                />
                <Button onClick={handleStartStream} danger>
                  Start interview
                </Button>
              </>
            )}

            {interview?.streamCohostToken && (
              <CopyLink
                name="guest"
                label="Link for guest to join"
                link={`${getEnvLink(env.REACT_APP_ENV)}/guest/${
                  interview?.streamCohostToken
                }`}
              />
            )}
          </>
        )}
      </>

      <div>
        <p>
          <strong>Interviewer notes:</strong> {interview?.notes || ''}
        </p>
      </div>

      <Form
        form={form}
        layout="horizontal"
        name="sendInviteForm"
        autoComplete="off"
        onFinish={handleFormFinish}
      >
        <Form.Item name="adminNotes" label={<Text>My Notes</Text>}>
          <Input placeholder="Add notes here" />
        </Form.Item>

        <Form.Item name="interviewType" label={<Text>Interview type</Text>}>
          <Select
            showSearch
            allowClear
            placeholder="Interview type"
            onChange={(event) => {
              setSelectedInterviewType(event as InterviewSourceType);
            }}
          >
            {interviewSourceTypeOptions?.map(({ value, label }) => {
              return (
                <Option key={value} value={value} name={label}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {interview?.status === InterviewStatus.Invited ||
        interview?.status === InterviewStatus.InviteViewed ? (
          <></>
        ) : (
          <>
            <Form.Item name="streamName" label={<Text>Stream Title</Text>}>
              <Input />
            </Form.Item>

            <Form.Item name="streamImage" label="Stream Cover Image">
              <CroppedPictureInput
                setPictureValidation={setPictureValidation}
                defaultImage={interview?.stream?.mainImageUrl || null}
                buttonTitle="Upload stream cover image"
              />
            </Form.Item>
          </>
        )}

        {showSearchStream && (
          <SearchStreamsComponent
            storeIds={[storeId as string]}
            selectedStreamOption={selectedStreamOption}
            setSelectedStreamOption={setSelectedStreamOption}
          />
        )}

        {showSearchMerch && (
          <SearchMerchComponent
            storeIds={[storeId as string]}
            selectedMerchOption={selectedMerchOption}
            setSelectedMerchOption={setSelectedMerchOption}
          />
        )}

        {showSearchProduct && (
          <SearchProductsComponent
            storeIds={[storeId as string]}
            selectedProductOption={selectedProductOption}
            setSelectedProductOption={setSelectedProductOption}
          />
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={updateInterviewInfoLoading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateInfoAdminModal;
