import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($next_token: String, $limit: Int) {
    getCampaigns(next_token: $next_token, limit: $limit) {
      campaigns {
        campaign_id
        campaign_filter_id
        status
        campaign_name
        brand_intro
        creative_direction
        uploads
        physical_product
        delivery_date
        brand_name
        website
        campaign_instruction
        encouraged
        avoid
        media_type
        post_type
        link_bio
        pinned_post
        suggested_caption
        hashtags
        profile_mentions
        promo_codes
        promo_url
        quantity_instruction
        brand_intro
        creative_direction
        post_asset
        script_url
      }
      next_token
    }
  }
`;

export const GET_CAMPAIGNS_FILTER_USER_BY_CAMPAIGN_FILTER_ID = gql`
  query GetCampaignFilterUserByCampaignFilterId(
    $campaign_filter_id: ID!
    $next_token: String
    $limit: Int
  ) {
    getCampaignFilterUserByCampaignFilterId(
      campaign_filter_id: $campaign_filter_id
      next_token: $next_token
      limit: $limit
    ) {
      stores {
        campaign_filter_user_id
        store_id
        store_name
        first_name
        last_name
        status
        slug
        avatarURL
        bio
        socialFollowing
      }
      next_token
    }
  }
`;

export const GET_CAMPAIGN_FILTER_ASSET_FILE_PRESIGNED_URL_BY_CAMPAIGN_FILTER_ID = gql`
  query GetCampaignFilterAssetFilePresignedUrlByCampaignFilterId(
    $campaign_filter_id: ID!
    $asset_filenames: [String!]!
  ) {
    getCampaignFilterAssetFilePresignedUrlByCampaignFilterId(
      campaign_filter_id: $campaign_filter_id
      asset_filenames: $asset_filenames
    ) {
      key
      url
    }
  }
`;
