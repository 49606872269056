import React, { useState } from 'react';
import { Button, Modal, Space, Spin } from 'antd';
import { campaignClient } from 'campaignClient';
import { useQuery } from '@apollo/client';
// Helpers
import { renderedCheckForBooleans } from 'helpers/utils';
import getEnvVariables from 'api/env';
// Api
import { GET_CAMPAIGNS } from 'api/campaign/queries';
// Types
import {
  GetCampaigns,
  GetCampaignsVariables,
  GetCampaigns_getCampaigns_campaigns,
} from 'api/campaign/types/GetCampaigns';
// UI
import Table from 'ui/Table';
// Components
import CampaignDetailsModal from 'pages/Campaigns/components/CampaignDetailsModal/CampaignDetailsModal';
// Styles
import styles from 'pages/Campaigns/components/CampaignsTable/CampaignsTable.module.scss';

const CampaignsTable = (): JSX.Element => {
  const [itemModalData, setItemModalData] =
    useState<GetCampaigns_getCampaigns_campaigns | null>(null);

  const showItemModal = (
    record: GetCampaigns_getCampaigns_campaigns | null
  ) => {
    setItemModalData(record);
  };

  const hideItemModal = () => {
    setItemModalData(null);
  };

  const { data, loading, refetch } = useQuery<
    GetCampaigns,
    GetCampaignsVariables
  >(GET_CAMPAIGNS, {
    variables: {
      limit: 10,
    },
    fetchPolicy: 'cache-and-network',
    client: campaignClient,
  });

  const handleOnClickLoadMore = async () => {
    if (data?.getCampaigns?.next_token) {
      await refetch({
        limit: (data?.getCampaigns.campaigns?.length ?? 0) + 10,
      });
    }
  };

  const columns = [
    {
      title: 'Campaign ID',
      dataIndex: 'campaign_id',
      width: 100,
    },
    {
      title: 'Campaign filter ID',
      dataIndex: 'campaign_filter_id',
      width: 100,
    },
    {
      title: 'Campaign name',
      dataIndex: 'campaign_name',
      width: 100,
    },
    {
      title: 'Brand name',
      dataIndex: 'brand_name',
      width: 100,
    },
    {
      title: 'Due date',
      dataIndex: 'delivery_date',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'Brand intro',
      dataIndex: 'brand_intro',
      width: 100,
    },
    {
      title: 'Creative direction',
      dataIndex: 'creative_direction',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
    },
    {
      title: 'Physical product',
      dataIndex: 'physical_product',
      align: 'center' as const,
      width: 100,
      render: renderedCheckForBooleans,
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 110,
      render: function ActionsWrap(
        id: string,
        record: GetCampaigns_getCampaigns_campaigns
      ) {
        return (
          <Space align="center" direction="vertical">
            <Button type="primary" onClick={() => showItemModal(record)}>
              Details
            </Button>
            <a
              href={`${getEnvVariables.CAMPAIGN_APP_URL}/${record.campaign_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View campaign
            </a>
          </Space>
        );
      },
    },
  ];

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <Table<GetCampaigns_getCampaigns_campaigns>
        columns={columns}
        data={data?.getCampaigns?.campaigns}
        scroll={{ x: 300 }}
        pagination={false}
      />

      <div className={styles.loadMoreBtn}>
        <Button
          type="primary"
          disabled={!data?.getCampaigns?.next_token}
          onClick={handleOnClickLoadMore}
        >
          Load More
        </Button>
      </div>

      <Modal
        title="Campaign details"
        visible={!!itemModalData}
        footer={null}
        onCancel={hideItemModal}
        width="90vw"
      >
        <CampaignDetailsModal record={itemModalData} />
      </Modal>
    </>
  );
};

export default CampaignsTable;
