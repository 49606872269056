import { notification } from 'antd';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { getAuthUserFromCookies } from 'helpers/cookies';
import { onError } from '@apollo/client/link/error';
import { handleAuthError } from 'helpers/auth';
// Config
import ENV from 'api/env';

// Create an HTTP link
const httpLink = createHttpLink({
  uri: ENV.CAMPAIGN_API_HOST,
});

// Create an error link
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const errorMessages = graphQLErrors.map(({ message }) => message);
      console.log(networkError, 'networkError');

      if (errorMessages.length) {
        return handleAuthError({ operation, forward, errorMessages });
      }
    }

    if (networkError) {
      const errorMessage =
        typeof networkError === 'string'
          ? networkError
          : JSON.stringify(networkError);
      notification.error({
        description:
          "Seems like You've lost the Internet connection. Try connecting a different Wi-Fi, or check the network cables, modem and router.",
        message: 'Network error',
        duration: 15,
      });
      console.log(`[Network error]: ${errorMessage}`);
    }
  }
);

// Create an auth link
const authLink = new ApolloLink((operation, forward) => {
  const authUserFromCookies = getAuthUserFromCookies();
  const tokenFromCookies = authUserFromCookies?.accessToken || '';

  operation.setContext(({ headers }: { headers: Record<string, string> }) => ({
    headers: {
      ...headers,
      authorization: tokenFromCookies ? `Bearer ${tokenFromCookies}` : '',
    },
  }));

  return forward(operation);
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const campaignClient = new ApolloClient({
  cache: new InMemoryCache(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: from([errorLink, authLink]).concat(httpLink),
});
