import { gql } from '@apollo/client';
// Fragments
import { MediaImageFields, MediaVideoFields } from './fragments';

export const GET_MEDIA_POST = gql`
  query GetMediaPost($input: GetMediaPostInput!) {
    getMediaPost(input: $input) {
      body
      createdAt
      id
      slug
      status
      scheduledAt
      hashtags {
        name
        id
      }
      scheduledAtOffset
      scheduledAtTzCode
      store {
        id
        slug
        lastName
        firstName
        role
        sports {
          id
          name
        }
        storeDetails {
          id
          storeName
          storeTitle
          avatarURL
        }
      }
      title
      ... on ImagePost {
        image {
          ...MediaImageFields
        }
      }
      ... on VideoPost {
        thumbnailKey
        thumbnailUrl
        video {
          ...MediaVideoFields
        }
      }
    }
  }
  ${MediaImageFields}
  ${MediaVideoFields}
`;

export const GET_MEDIA_POSTS = gql`
  query GetMediaPosts($input: GetMediaPostsInput!) {
    getMediaPosts(input: $input) {
      total
      offset
      limit
      entities {
        body
        createdAt
        id
        slug
        status
        scheduledAt
        scheduledAtOffset
        scheduledAtTzCode
        hashtags {
          name
          id
        }
        store {
          id
          slug
          lastName
          firstName
          role
          sports {
            id
            name
          }
          storeDetails {
            id
            storeName
            storeTitle
            avatarURL
          }
        }
        title
        ... on ImagePost {
          image {
            ...MediaImageFields
          }
        }
        ... on VideoPost {
          thumbnailKey
          thumbnailUrl
          video {
            ...MediaVideoFields
          }
        }
      }
    }
  }
  ${MediaImageFields}
  ${MediaVideoFields}
`;
