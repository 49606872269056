import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Modal, Tabs } from 'antd';
// Helpers
import { getPublicStreamDate } from 'helpers/utils';
// Api
import { GET_INTERVIEWS } from 'api/interview/queries';
// Types
import {
  GetInterviews,
  GetInterviewsVariables,
  GetInterviews_getInterviews_entities,
  GetInterviews_getInterviews_entities_auditLogs,
  GetInterviews_getInterviews_entities_interviewee_sports,
  GetInterviews_getInterviews_entities_stream_timeZone,
} from 'api/interview/types/GetInterviews';
import { SortedInfo, TableFilter } from 'ui/Table';
import {
  SortDirection,
  InterviewerTasksOrderBy,
  InterviewStatus,
  InterviewSourceType,
} from 'api/graphql-global-types';
// UI
import Table, { Column } from 'ui/Table';
// Components
import InterviewDetailsModal from '../components/InterviewDetailsModal/InterviewDetailsModal';
import InterviewsActions from '../components/InterviewsActions/InterviewsActions';
import SendInviteModal from '../components/SendInviteModal/SendInviteModal';
import AuditLogs from '../components/AuditLogs/AuditLogs';
import UpdateInfoAdminModal from '../components/UpdateInfoAdminModal/UpdateInfoModal';
import ScheduleInterviewAdminModal from '../components/ScheduleInterviewAdminModal/ScheduleInterviewAdminModal';
// import CompleteInterviewModalAdmin from '../components/CompleteInterviewModalAdmin/CompleteInterviewModalAdmin';
// Columns
import {
  createdAtColumn,
  interviewTypeColumn,
  statusColumn,
} from 'pages/Interviews/common/Columns';
// Styles
import styles from './InterviewsTable.module.scss';

export const refetchQueries = [
  {
    query: GET_INTERVIEWS,
    variables: {
      input: {
        direction: SortDirection.DESC,
        orderBy: InterviewerTasksOrderBy.createdAt,
        limit: 10,
        offset: 0,
      },
    },
  },
];

interface StatusMap {
  [key: string]: InterviewStatus;
}

const { TabPane } = Tabs;

export type InterviewsFormattedData = Omit<
  GetInterviews_getInterviews_entities,
  '__typename'
> & {
  interviewId: string;
  intervieweeName: string;
  status: InterviewStatus;
  interviewDate: string;
  interviewType: InterviewSourceType;
  interviewerName: string;
  scheduleDate: string;
  timeZone: GetInterviews_getInterviews_entities_stream_timeZone | string;
};

const InterviewsTable = (): JSX.Element => {
  const [isDetailsModalVisible, setIsDetailsModalVisible] =
    useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] =
    useState<InterviewsFormattedData | null>(null);

  const [isReschedule, setIsReschedule] = useState<boolean>(false);

  const [isScheduleModalVisible, setIsScheduleModalVisible] =
    useState<boolean>(false);
  const [isUpdateInfoModalVisible, setIsUpdateInfoModalVisible] =
    useState<boolean>(false);

  // const [isCompleteModalVisible, setIsCompleteModalVisible] =
  //   useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<InterviewerTasksOrderBy>>({
    order: SortDirection.DESC,
    key: InterviewerTasksOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const [isSendInviteModalVisible, setIsSendInviteModalVisible] =
    useState<boolean>(false);

  const showDetailsModal = (record: InterviewsFormattedData | null) => {
    setSelectedRecord(record);
    setIsDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setIsDetailsModalVisible(false);
    setSelectedRecord(null);
  };

  const showSendInviteModal = () => {
    setIsSendInviteModalVisible(true);
  };

  const hideSendInviteModal = () => {
    setIsSendInviteModalVisible(false);
  };

  const showScheduleModal = (
    record: InterviewsFormattedData | null,
    isReschedule?: boolean
  ) => {
    setIsScheduleModalVisible(true);
    setSelectedRecord(record);
    setIsReschedule(isReschedule || false);
  };

  const hideScheduleModal = () => {
    setIsScheduleModalVisible(false);
    setSelectedRecord(null);
    setIsReschedule(false);
  };

  const showUpdateInfoModal = (record: InterviewsFormattedData | null) => {
    setIsUpdateInfoModalVisible(true);
    setSelectedRecord(record);
  };

  const hideUpdateInfoModal = () => {
    setIsUpdateInfoModalVisible(false);
    setSelectedRecord(null);
  };

  // const showCompleteModal = (record: InterviewsFormattedData | null) => {
  //   setIsCompleteModalVisible(true);
  //   setSelectedRecord(record);
  // };

  // const hideCompleteModal = () => {
  //   setIsCompleteModalVisible(false);
  //   setSelectedRecord(null);
  // };

  const [tabKey, setTabKey] = useState<string>('all');

  const handleTabChange = (activeKey: string) => {
    setTabKey(activeKey);
    setSearchFilters([]);
    setCurrentPage(1);
  };

  const getInterviewsInput: any = () => {
    let input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    const statusMap: StatusMap = {
      Canceled: InterviewStatus.Canceled,
      Completed: InterviewStatus.Completed,
      InviteViewed: InterviewStatus.InviteViewed,
      Invited: InterviewStatus.InviteViewed,
      ScheduleAttempted: InterviewStatus.ScheduleAttempted,
      Scheduled: InterviewStatus.Scheduled,
    };

    if (Object.prototype.hasOwnProperty.call(statusMap, tabKey)) {
      input = {
        ...input,
        statuses: [statusMap[tabKey]],
      };
    }

    if (input.orderBy === 'scheduleDate') {
      input.orderBy = 'scheduledAt';
    }

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'interviewType') {
        return (input['sources'] = value);
      }
      if (key === 'scheduleDate') {
        return (input['orderBy'] = 'scheduleAt');
      }
      if (key === 'interviewId') {
        return (input['id'] = value);
      }

      input[key] = value;
    });

    const { ...rest } = input;

    return {
      input: {
        ...rest,
      },
    };
  };

  const { data, loading } = useQuery<GetInterviews, GetInterviewsVariables>(
    GET_INTERVIEWS,
    {
      variables: { ...getInterviewsInput() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const counters = data?.getInterviews?.counters;

  const interviewIdColumn = {
    title: 'Task ID',
    dataIndex: 'interviewId',
    key: 'interviewId',
    align: 'center' as const,
    width: 130,
    withSearch: true,
    forceSearchRender: true,
    render: function InterviewIdWrap(
      id: string,
      record: InterviewsFormattedData
    ) {
      return (
        <span
          onKeyDown={() => showDetailsModal(record)}
          onClick={() => showDetailsModal(record)}
          className={styles.detailsModalLink}
          role="button"
          tabIndex={0}
        >
          {record.id}
        </span>
      );
    },
  };

  const intervieweeNameColumn = {
    title: 'Store Name',
    dataIndex: 'intervieweeName',
    key: 'intervieweeName',
    align: 'left' as const,
    width: 120,
    withSearch: true,
  };

  const interviewerColumn = {
    title: 'Assigned interviewer',
    dataIndex: 'interviewerName',
    key: 'interviewerName',
    align: 'left' as const,
    width: 110,
    withSearch: true,
  };

  const sportsColumn = {
    title: 'Sports',
    dataIndex: 'sports',
    align: 'center' as const,
    width: 150,
    render: function Sports(
      sports: GetInterviews_getInterviews_entities_interviewee_sports[]
    ) {
      return sports?.map((item) => item.name).join(', ') || '';
    },
  };

  const auditLogsColumn = {
    title: 'Audit logs',
    dataIndex: 'auditLogs',
    align: 'center' as const,
    width: 140,
    render: function AuditLogsWrap(
      auditLogs: GetInterviews_getInterviews_entities_auditLogs[]
    ) {
      return <AuditLogs data={auditLogs} />;
    },
  };

  const actionColumn = {
    title: 'Action',
    dataIndex: 'id',
    fixed: 'right' as const,
    align: 'center' as const,
    width: 90,
    render: function ActionsWrap(id: string, record: InterviewsFormattedData) {
      return (
        <InterviewsActions
          showScheduleModal={showScheduleModal}
          showUpdateInfoModal={showUpdateInfoModal}
          // showCompleteModal={showCompleteModal}
          interview={record}
        />
      );
    },
  };

  const scheduleDateColumn = {
    title: 'Schedule date',
    dataIndex: 'scheduleDate',
    key: 'scheduleDate',
    align: 'center' as const,
    width: 110,
    sorterType: 'date',
    render: (id: string, record: InterviewsFormattedData): JSX.Element => {
      return (
        <>
          {record?.stream?.scheduleDate
            ? getPublicStreamDate(
                record?.scheduleDate,
                record?.stream?.timeZone
              )
            : '-'}
        </>
      );
    },
  };

  const adminColumns: Column<
    InterviewsFormattedData,
    InterviewerTasksOrderBy
  >[] = [
    interviewIdColumn,
    intervieweeNameColumn,
    createdAtColumn,
    scheduleDateColumn,
    interviewTypeColumn,
    interviewerColumn,
    statusColumn,
    sportsColumn,
    auditLogsColumn,
    actionColumn,
  ];

  const getFormattedData = (
    data: GetInterviews | undefined
  ): InterviewsFormattedData[] => {
    const result: InterviewsFormattedData[] = [];

    data?.getInterviews?.entities?.forEach(
      (i: GetInterviews_getInterviews_entities) => {
        result.push({
          ...i,
          interviewId: i.id,
          intervieweeName: i?.interviewee?.storeDetails?.storeName || '',
          status: i.status || '',
          interviewDate: i.createdAt || '',
          interviewType: i.source.type || '',
          interviewerName: `${i?.interviewer?.firstName || ''} ${
            i?.interviewer?.lastName || ''
          }`,
          scheduleDate: i?.stream?.scheduleDate || '',
          timeZone: i?.stream?.timeZone || '',
        });
      }
    );

    return result;
  };

  const renderTable = () => (
    <Table<InterviewsFormattedData, InterviewerTasksOrderBy>
      columns={adminColumns}
      data={getFormattedData(data)}
      scroll={{ x: 1280 }}
      loading={loading}
      total={data?.getInterviews?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );

  return (
    <>
      <>
        <div className={styles.newInterviewBtnWrapper}>
          <Button type="primary" onClick={showSendInviteModal}>
            Send interview invitation
          </Button>
        </div>

        <Tabs
          activeKey={tabKey}
          onChange={handleTabChange}
          className="countersCustomTabs"
        >
          <TabPane tab="All" key="all">
            {renderTable()}
          </TabPane>

          <TabPane tab={`Canceled ${counters?.Canceled || 0}`} key="Canceled">
            {renderTable()}
          </TabPane>
          <TabPane
            tab={`Completed ${counters?.Completed || 0}`}
            key="Completed"
          >
            {renderTable()}
          </TabPane>
          <TabPane
            tab={`Invite Viewed ${counters?.InviteViewed || 0}`}
            key="InviteViewed"
          >
            {renderTable()}
          </TabPane>

          <TabPane
            tab={`Schedule Attempted ${counters?.ScheduleAttempted || 0}`}
            key="ScheduleAttempted"
          >
            {renderTable()}
          </TabPane>

          <TabPane
            tab={`Scheduled ${counters?.Scheduled || 0}`}
            key="Scheduled"
          >
            {renderTable()}
          </TabPane>
        </Tabs>
      </>

      <Modal
        title="Interview invitation"
        visible={!!isSendInviteModalVisible}
        footer={null}
        onCancel={hideSendInviteModal}
      >
        <SendInviteModal onClose={hideSendInviteModal} />
      </Modal>

      <Modal
        title="Interview details"
        visible={!!selectedRecord && isDetailsModalVisible}
        destroyOnClose
        onCancel={hideDetailsModal}
        footer={null}
      >
        <InterviewDetailsModal
          interviewId={selectedRecord?.id || ''}
          hideDetailsModal={hideDetailsModal}
        />
      </Modal>

      <Modal
        title="Update Interview info"
        visible={!!selectedRecord && isUpdateInfoModalVisible}
        onCancel={hideUpdateInfoModal}
        destroyOnClose
        footer={null}
      >
        <UpdateInfoAdminModal
          interviewId={selectedRecord?.id || ''}
          hideUpdateInfoModal={hideUpdateInfoModal}
        />
      </Modal>

      <Modal
        title="Schedule interview"
        visible={!!selectedRecord && isScheduleModalVisible}
        onCancel={hideScheduleModal}
        destroyOnClose
        footer={null}
      >
        <ScheduleInterviewAdminModal
          isReschedule={isReschedule}
          interview={selectedRecord || null}
          hideScheduleModal={hideScheduleModal}
        />
      </Modal>

      {/* hidden for now */}
      {/* <Modal
        title="Complete interview"
        visible={!!selectedRecord && isCompleteModalVisible}
        onCancel={hideCompleteModal}
        destroyOnClose
        footer={null}
      >
        <CompleteInterviewModalAdmin
          interviewId={selectedRecord?.id || ''}
          storeId={selectedRecord?.interviewee?.id || ''}
          hideCompleteModal={hideCompleteModal}
        />
      </Modal> */}
    </>
  );
};

export default InterviewsTable;
