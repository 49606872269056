// Types
import { SocialMediaContentType } from 'api/graphql-campaign-global-types';

export const getContentTypeText = (
  contentTypes: SocialMediaContentType[] | null
): string[] => {
  if (!contentTypes) return [];

  return contentTypes.map((type) => {
    switch (type) {
      case SocialMediaContentType.FB_POST:
        return 'Facebook Post';
      case SocialMediaContentType.FB_STORY:
        return 'Facebook Story';
      case SocialMediaContentType.IG_LIVE:
        return 'Instagram Live';
      case SocialMediaContentType.IG_POST:
        return 'Instagram Post';
      case SocialMediaContentType.IG_REEL:
        return 'Instagram Reel';
      case SocialMediaContentType.IG_STORY:
        return 'Instagram Story';
      case SocialMediaContentType.ML_STREAM:
        return 'ML Stream';
      case SocialMediaContentType.TT_VIDEO:
        return 'TikTok Video';
      case SocialMediaContentType.YT_LIVE:
        return 'YouTube Live';
      case SocialMediaContentType.YT_SHORT:
        return 'YouTube Short';
      case SocialMediaContentType.YT_VIDEO:
        return 'YouTube Video';
      default:
        return 'Unknown';
    }
  });
};
