import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Spin, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import env from 'api/env';
// Constants
import { commonTimeFormat } from 'constants/global';
// Helpers
import { uploadImages } from 'helpers/interview';
import { getEnvLink } from 'helpers/getEnvLink';
import { getPublicStreamDate } from 'helpers/utils';
import { createPublicStreamLink } from 'helpers/links';
// Api
import { GET_INTERVIEW } from 'api/interview/queries';
import {
  CREATE_STREAM_IMAGE_ADMIN,
  UPDATE_INTERVIEW_INFO_ADMIN,
} from 'api/interview/mutations';
// Types
import {
  CreateStreamImageAdmin,
  CreateStreamImageAdminVariables,
} from 'api/interview/types/CreateStreamImageAdmin';
import { InterviewStatus } from 'api/graphql-global-types';
import {
  GetInterview,
  GetInterviewVariables,
} from 'api/interview/types/GetInterview';
import {
  UpdateInterviewInfoAdmin,
  UpdateInterviewInfoAdminVariables,
} from 'api/interview/types/UpdateInterviewInfoAdmin';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import CopyLink from 'ui/CopyLink/CopyLink';
// Components
import CroppedPictureInput from 'components/common/CroppedPictureInput/CroppedPictureInput';
// Styles
import styles from './InterviewDetailsModal.module.scss';

const { Text } = Typography;

type InterviewDetailsModalProps = {
  interviewId: string;
  hideDetailsModal: () => void;
};

const InterviewDetailsModal = ({
  interviewId,
  hideDetailsModal,
}: InterviewDetailsModalProps): JSX.Element | null => {
  const [, setPictureValidation] = useState<string>('');
  const { data, loading } = useQuery<GetInterview, GetInterviewVariables>(
    GET_INTERVIEW,
    {
      variables: {
        input: {
          id: interviewId,
        },
      },
      skip: !interviewId,
      fetchPolicy: 'cache-and-network',
    }
  );

  const [
    updateInterviewInfoAdmin,
    { loading: updateInterviewInfoAdminLoading },
  ] = useMutation<UpdateInterviewInfoAdmin, UpdateInterviewInfoAdminVariables>(
    UPDATE_INTERVIEW_INFO_ADMIN
  );

  const [createStreamImageAdmin] = useMutation<
    CreateStreamImageAdmin,
    CreateStreamImageAdminVariables
  >(CREATE_STREAM_IMAGE_ADMIN);

  const interview = data?.getInterview || null;
  const isCompleted = data?.getInterview?.status === InterviewStatus.Completed;
  const isCanceled = data?.getInterview?.status === InterviewStatus.Canceled;
  const isCanceledOrCompleted = isCanceled || isCompleted;
  const isScheduled = data?.getInterview?.status === InterviewStatus.Scheduled;
  const publicStreamLink =
    interview?.interviewer?.slug && interview?.stream?.slug
      ? createPublicStreamLink(
          interview.interviewer.slug,
          interview.stream.slug
        )
      : null;

  const [form] = Form.useForm();

  const handleFormFinish = async (values: any) => {
    const shouldUpload = Boolean(Array.isArray(values.streamImage));

    if (shouldUpload) {
      const contentType = values?.streamImage[0]?.type;
      const ext = contentType?.split('/')[1];
      const interviewerId = interview?.interviewer?.id as string;
      if (ext && contentType && interviewerId) {
        const { data } = await createStreamImageAdmin({
          variables: {
            input: {
              storeId: interviewerId,
              files: [
                {
                  contentType,
                  ext,
                },
              ],
            },
          },
        });

        if (data) {
          const imagesUrls = await uploadImages(
            data?.createStreamImageAdmin,
            values.streamImage
          );

          await updateInterviewInfoAdmin({
            variables: {
              input: {
                id: interviewId,
                adminNotes: form.getFieldValue('adminNotes'),
                scheduledMeetingLink: form.getFieldValue(
                  'scheduledMeetingLink'
                ),
                name: values.streamName ? values.streamName : null,
                imageFileKey: imagesUrls[0],
              },
            },
          });
          successNotification('Information updated successfully');
          hideDetailsModal();
        }
      }
    } else {
      try {
        await updateInterviewInfoAdmin({
          variables: {
            input: {
              id: interviewId,
              name: values.streamName ? values.streamName : null,
              adminNotes: form.getFieldValue('adminNotes'),
            },
          },
        });

        successNotification('Information updated successfully');
        hideDetailsModal();
      } catch (err) {
        errorNotification((err as Error)?.message || 'Something went wrong');
      }
    }
  };

  const handleStartStream = () => {
    window.open(
      `${getEnvLink(env.REACT_APP_ENV)}/${
        interview?.interviewer?.slug
      }/start-stream/${interview?.stream?.slug}`,
      '_blank'
    );
  };

  useEffect(() => {
    if (interview) {
      form.setFieldsValue({
        adminNotes: interview.adminNotes,
        streamName: interview.stream?.name,
        streamImage: interview.stream?.mainImageUrl,
      });
    }
  }, [interview, form]);

  if (loading || updateInterviewInfoAdminLoading) {
    return <Spin />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <div>
        <p>
          <strong>Task ID:</strong> {interview?.id || ''}
        </p>
      </div>

      <div>
        <p>
          <strong>Account: </strong>
          <a
            href={`${getEnvLink(env.REACT_APP_ENV)}/${
              interview?.interviewee?.slug || ''
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {interview?.interviewee?.storeDetails?.storeName || ''}
          </a>
        </p>
      </div>

      <div>
        <p>
          <strong>Status:</strong> {interview?.status || ''}
        </p>
      </div>

      <div>
        <p>
          <strong>Invite date: </strong>
          {interview?.createdAt ? (
            <>{moment(interview?.createdAt).format(commonTimeFormat)}</>
          ) : (
            '-'
          )}
        </p>
      </div>

      <div>
        <p>
          <strong>Interview date: </strong>
          {interview?.stream?.scheduleDate ? (
            <>
              {getPublicStreamDate(
                interview?.stream?.scheduleDate,
                interview?.stream?.timeZone
              )}
            </>
          ) : (
            '-'
          )}
        </p>
      </div>

      {!isCanceledOrCompleted && (
        <CopyLink
          name="schedule-url"
          label="Schedule interview link"
          link={`${getEnvLink(env.REACT_APP_ENV)}/schedule-interview/${
            interview?.id
          }`}
        />
      )}

      <>
        {isScheduled && (
          <>
            {interview?.stream?.slug && (
              <>
                <CopyLink
                  name="stream-url"
                  label="Start Stream link"
                  link={`${getEnvLink(env.REACT_APP_ENV)}/${
                    interview?.interviewer?.slug
                  }/start-stream/${interview?.stream?.slug}`}
                />
                <Button onClick={handleStartStream} danger>
                  Start interview
                </Button>
              </>
            )}

            {interview?.streamCohostToken && (
              <CopyLink
                name="guest"
                label="Link for guest to join"
                link={`${getEnvLink(env.REACT_APP_ENV)}/guest/${
                  interview?.streamCohostToken
                }`}
              />
            )}
          </>
        )}
      </>

      <div>
        <p>
          <strong>Interviewer notes:</strong> {interview?.notes || ''}
        </p>
      </div>

      <Form
        form={form}
        layout="horizontal"
        name="sendInviteForm"
        autoComplete="off"
        onFinish={handleFormFinish}
      >
        <Form.Item name="adminNotes" label={<Text>My Notes</Text>}>
          <Input placeholder="Add notes here" />
        </Form.Item>

        {interview?.status === InterviewStatus.Invited ||
        interview?.status === InterviewStatus.InviteViewed ? (
          <></>
        ) : (
          <>
            <Form.Item name="streamName" label={<Text>Stream Title</Text>}>
              <Input />
            </Form.Item>

            <Form.Item name="streamImage" label="Stream Cover Image">
              <CroppedPictureInput
                setPictureValidation={setPictureValidation}
                defaultImage={interview?.stream?.mainImageUrl || null}
                buttonTitle="Upload stream cover image"
              />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={updateInterviewInfoAdminLoading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>

      {publicStreamLink && (
        <p>
          <strong>Public interview link: </strong>
          <div>
            <a
              href={publicStreamLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {publicStreamLink}
            </a>
          </div>
        </p>
      )}

      {isCompleted && (
        <>
          <p>
            <strong>Stream record links:</strong>
          </p>

          {interview?.stream?.streamRecords?.map((item) => {
            return (
              <div key={item.id} className={styles.linksWrapper}>
                <a href={item.videoURL || ''} target="__blank">
                  {item.videoURL}
                </a>

                <a
                  className={styles.downloadLink}
                  key={item.id}
                  href={item.downloadVideoURL || ''}
                  download={item.id}
                  title={item.videoURL || ''}
                >
                  <DownloadOutlined /> Download file
                </a>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default InterviewDetailsModal;
