/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CampaignFilterUserStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  INVITED = "INVITED",
  PROPOSED = "PROPOSED",
  REMOVED = "REMOVED",
}

export enum CampaignStatus {
  COMPLETED = "COMPLETED",
  DETAIL_FILLED = "DETAIL_FILLED",
  PAID_AND_ACTIVE = "PAID_AND_ACTIVE",
}

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum SocialMediaContentType {
  FB_POST = "FB_POST",
  FB_STORY = "FB_STORY",
  IG_LIVE = "IG_LIVE",
  IG_POST = "IG_POST",
  IG_REEL = "IG_REEL",
  IG_STORY = "IG_STORY",
  ML_STREAM = "ML_STREAM",
  TT_VIDEO = "TT_VIDEO",
  YT_LIVE = "YT_LIVE",
  YT_SHORT = "YT_SHORT",
  YT_VIDEO = "YT_VIDEO",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
