import React from 'react';
import moment from 'moment';
import { Button, Image, Spin } from 'antd';
import { CSVLink } from 'react-csv';
import { useQuery } from '@apollo/client';
import { campaignClient } from 'campaignClient';
// Helpers
import { isImage } from 'helpers/isImage';
import { getContentTypeText } from 'helpers/socialMediaContentTypes';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Types
import { SocialMediaContentType } from 'api/graphql-campaign-global-types';
import { GetCampaigns_getCampaigns_campaigns } from 'api/campaign/types/GetCampaigns';
import {
  GET_CAMPAIGNS_FILTER_USER_BY_CAMPAIGN_FILTER_ID,
  GET_CAMPAIGN_FILTER_ASSET_FILE_PRESIGNED_URL_BY_CAMPAIGN_FILTER_ID,
} from 'api/campaign/queries';
import {
  GetCampaignFilterUserByCampaignFilterId,
  GetCampaignFilterUserByCampaignFilterIdVariables,
  GetCampaignFilterUserByCampaignFilterId_getCampaignFilterUserByCampaignFilterId_stores,
} from 'api/campaign/types/GetCampaignFilterUserByCampaignFilterId';
import {
  GetCampaignFilterAssetFilePresignedUrlByCampaignFilterId,
  GetCampaignFilterAssetFilePresignedUrlByCampaignFilterIdVariables,
  GetCampaignFilterAssetFilePresignedUrlByCampaignFilterId_getCampaignFilterAssetFilePresignedUrlByCampaignFilterId,
} from 'api/campaign/types/GetCampaignFilterAssetFilePresignedUrlByCampaignFilterId';
// Ui
import { successNotification } from 'ui/Notification';
// Icons
import { DownloadOutlined } from '@ant-design/icons';
// Style
import styles from './CampaignDetailsModal.module.scss';

type CampaignDetailsModalProps = {
  record: GetCampaigns_getCampaigns_campaigns | null;
};

const CampaignDetailsModal = ({
  record,
}: CampaignDetailsModalProps): JSX.Element | null => {
  const { data, loading } = useQuery<
    GetCampaignFilterUserByCampaignFilterId,
    GetCampaignFilterUserByCampaignFilterIdVariables
  >(GET_CAMPAIGNS_FILTER_USER_BY_CAMPAIGN_FILTER_ID, {
    variables: {
      campaign_filter_id: record?.campaign_filter_id || '',
      limit: 10_000,
    },
    client: campaignClient,
  });

  const { data: presignedUrlsData, loading: loadingPresignedUrls } = useQuery<
    GetCampaignFilterAssetFilePresignedUrlByCampaignFilterId,
    GetCampaignFilterAssetFilePresignedUrlByCampaignFilterIdVariables
  >(GET_CAMPAIGN_FILTER_ASSET_FILE_PRESIGNED_URL_BY_CAMPAIGN_FILTER_ID, {
    variables: {
      campaign_filter_id: record?.campaign_filter_id ?? '',
      asset_filenames: record?.uploads as string[],
    },
    skip: !record?.uploads?.length,
    client: campaignClient,
  });

  const onCSVButtonClick = () => {
    successNotification('File downloading');
  };

  const stores: GetCampaignFilterUserByCampaignFilterId_getCampaignFilterUserByCampaignFilterId_stores[] =
    data?.getCampaignFilterUserByCampaignFilterId?.stores?.map((item) => ({
      ...item,
    })) || [];

  return (
    <div className={styles.root}>
      <div>
        <div>
          <p className={styles.label}>ID:</p>
          <p className={styles.value}>{record?.campaign_id || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Campaign filter ID:</p>
          <p className={styles.value}>{record?.campaign_filter_id || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Campaign Name:</p>
          <p className={styles.value}>{record?.campaign_name || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Brand Name:</p>
          <p className={styles.value}>{record?.brand_name || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Website:</p>
          <p className={styles.value}>{record?.website || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Campaign instructions:</p>
          <p className={styles.value}>{record?.campaign_instruction || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Due date:</p>
          <p className={styles.value}>
            {moment(record?.delivery_date).format('MM/DD?YYYY')}
          </p>
        </div>

        <div>
          <p className={styles.label}>Encouraged:</p>
          <p className={styles.value}>{record?.encouraged || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Please avoid:</p>
          <p className={styles.value}>{record?.avoid || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Platform:</p>
          {/* TODO: uncomment platform once it's added to the query */}
          {/* <p className={styles.value}>{record?.platform|| ''}</p> */}
        </div>

        <div>
          <p className={styles.label}>Type of media:</p>
          <p className={styles.value}>
            {record?.media_type?.map((item) => item?.toLowerCase()).join(', ')}
          </p>
        </div>

        <div>
          <p className={styles.label}>Post type:</p>
          <p className={styles.value}>
            {getContentTypeText(
              (record?.post_type as SocialMediaContentType[]) || null
            ).join(', ')}
          </p>
        </div>

        <div>
          <p className={styles.label}>Link in bio:</p>
          <p className={styles.value}> {record?.link_bio ? 'Yes' : 'No'}</p>
        </div>

        <div>
          <p className={styles.label}>Pinned post:</p>
          <p className={styles.value}> {record?.pinned_post ? 'Yes' : 'No'}</p>
        </div>

        <div>
          <p className={styles.label}>Suggested caption:</p>
          <p className={styles.value}> {record?.suggested_caption || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Hashtags:</p>
          <p className={styles.value}> {record?.hashtags || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Profile mentions:</p>
          <p className={styles.value}> {record?.profile_mentions || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Promotion urls:</p>
          {record?.promo_url?.map((item) => {
            return (
              <p key={item} className={styles.value}>
                {item}
              </p>
            );
          })}
        </div>

        <div>
          <p className={styles.label}>Quantity instructions:</p>
          <p className={styles.value}> {record?.quantity_instruction || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Script url:</p>
          <p className={styles.value}> {record?.script_url || ''}</p>
        </div>

        <div>
          <p className={styles.label}>Examples:</p>
          {record?.uploads?.length &&
          presignedUrlsData
            ?.getCampaignFilterAssetFilePresignedUrlByCampaignFilterId
            ?.length ? (
            presignedUrlsData?.getCampaignFilterAssetFilePresignedUrlByCampaignFilterId?.map(
              (
                item: GetCampaignFilterAssetFilePresignedUrlByCampaignFilterId_getCampaignFilterAssetFilePresignedUrlByCampaignFilterId
              ) => {
                const fileExtension = item?.key?.split('.')[1] || '';

                const isImageKey = isImage(fileExtension);
                return (
                  <div key={item.key}>
                    {isImageKey ? (
                      <Image
                        fallback={FALLBACK_IMAGE}
                        key={item.key}
                        width={200}
                        height={200}
                        src={item.url || ''}
                        style={{ objectFit: 'cover' }}
                        alt={item.key || 'uploads'}
                      />
                    ) : (
                      <>
                        <a
                          key={item.key}
                          href={item.url}
                          download={item.key}
                          title={item.key}
                        >
                          <DownloadOutlined /> Download file
                        </a>
                      </>
                    )}
                  </div>
                );
              }
            )
          ) : (
            <p>no example</p>
          )}
        </div>
      </div>

      <div>
        <h1>Stores: </h1>

        {loading || loadingPresignedUrls ? (
          <Spin />
        ) : (
          <Button type="primary">
            <CSVLink
              filename={record?.campaign_filter_id || ''}
              data={stores}
              className="btn btn-primary"
              onClick={onCSVButtonClick}
            >
              Export to CSV
            </CSVLink>
          </Button>
        )}
      </div>
    </div>
  );
};

export default CampaignDetailsModal;
